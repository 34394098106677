import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import React from 'react';
import Collapsible from 'react-collapsible';
import DatePicker from 'react-date-picker';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TreeMenu, { defaultChildren, ItemComponent } from 'react-simple-tree-menu';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { navigate } from "@reach/router";


//////////
//  Data Functions
//////////

function arrayListToNodes(arrayList, referenceDict) {
  // takes an array of array and return the
  // tree format.
  referenceDict = referenceDict || {}; //

  // 1. make it a dict-of-dict for uniqueness
  var heirarchyDict = {}

  arrayList.forEach((grouplist) => applyHeirarchy(heirarchyDict, grouplist, ""));
  // heirarchyDict is now e.g. { c360_dataset: { user: { foo: {}, bar: {} }, common: {} } }
  // with each obj having at least one `__dkey`

  // 2. from dict-of-dict, transform to node tree
  let heirarchyTree = heirarchyDictToNodes(heirarchyDict, referenceDict);

  return heirarchyTree;
}

function applyHeirarchy(obj, list, parents) {
  if ( obj[list[0]] == null ) { obj[list[0]] = {__dkey: parents + "/" + list[0]} };

  if ( list.length > 1 ) {
      // multiple group in list, apply heirarchy for children
      applyHeirarchy(obj[list[0]], list.slice(1), parents + "/" + list[0])
  }
  // function operates directly to obj so it does not return
}

function heirarchyDictToNodes(obj, referenceDict) {
  var nodeList = []
  var path
  Object.entries(obj).forEach(
      ([key, value], i) => {
          if (key == "__dkey") return;
          path = obj.__dkey
          if (path) {
            if (path.slice(1) == "/") {
              path.substring(1)
            }
          }
          nodeList.push({
              key: key,
              label: key,
              // having more than 1 keys means dataset (all obj has __dkey)
              datasetinfo: Object.keys(value).length > 1 ? referenceDict[value.__dkey] : null,
              tableinfo: Object.keys(value).length <= 1 ? referenceDict[value.__dkey] : null,
              nodes: heirarchyDictToNodes(value, referenceDict),
              dataset_id: referenceDict[value.__dkey.dataset_id]
          })
      }
  )
  nodeList.sort((a, b) => {
    if (a.key < b.key) return -1;
    if (a.key > b.key) return 1;
    return 0;
  });

  return nodeList;
}

//////////
//  Data Functions - End
//////////


class DatasetList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      data: null,
      isLoading: null,
      initialActiveKey: "common/",
      initialOpenNodes: ['common']
    };
  }

  componentDidMount() {
    this.getInitialActiveKey();
    this.fetchData();
  }

  onClickAddDataset = () => {
    this.props.onClickAddDataset();
    this.dropDownClick();
  }

  dropDownClick = () => {
    let dropdown = document.getElementById("add-dropdown")
    if (dropdown) {
      if (dropdown.className.includes("is-active")) {
        dropdown.classList.remove("is-active")
      } else {
        dropdown.classList.add("is-active")
      }
    }
  }

  dropDownFilterClick = () => {
    let dropdown = document.getElementById("add-filter-dropdown")
    if (dropdown) {
      if (dropdown.className.includes("is-active")) {
        dropdown.classList.remove("is-active")
      } else {
        dropdown.classList.add("is-active")
      }
    }
  }

  getAllBefore(current, array) {
    var i = array.indexOf(current);
    return i > -1 ? array.slice(0, i) : [];
  }

  getAllBeforeList(nodeBeforeList, node) {
    let nodeList = ""
    if (nodeBeforeList.length > 0) {
      nodeBeforeList.forEach(nodeBefore => {
        if (nodeList == "") {
          nodeList = nodeBefore
        } else {
          nodeList = nodeList + "/" + nodeBefore
        }
      })
      return nodeList + "/" + node
    } else {
      return node
    }
  }

  getInitialActiveKey() {
    let url = window.location.href
    url = url.split("?")[0]
    // remove last "/"
    if (url.slice(-1) == "/") {
      url = url.slice(0, -1)
    }
    // split by first occurence of "dataset"
    url = url.substring(url.indexOf('dataset'))
    // remove "dataset/"
    url = url.substring(8)
    let initialOpenNodes = []
    let initialNodes = url.split("/")
    let nodeBeforeList = []
    initialNodes.forEach(node => {
      nodeBeforeList = this.getAllBefore(node, initialNodes)
      if (nodeBeforeList == []) { // first node
        initialOpenNodes.push(node)
      } else {
        initialOpenNodes.push(this.getAllBeforeList(nodeBeforeList, node))
      }
    })
    if (url) {
      this.setState({
        initialActiveKey: url,
        initialOpenNodes: initialOpenNodes
      })
    }
  }

  fetchData() {
    const callApiUrl = 'dataset/list'; // TODO: actual implementation with date_picker
    this.setState({ isLoading: true });

    callApi(
      callApiUrl,
      (result) => {
        const loadedResult = result.datasets;
        if (loadedResult == null) throw Error('Invalid DatasetList received.');
        if (loadedResult === []) {
          // no permission to data
          this.setState({
            error: null,
            data: [],
          });
        } else {
          this.setState({
            error: null,
            data: loadedResult
          });
          this.setState({
            isLoading: false,
          });
        }
      },
      (error) => this.setState({ error, isLoading: false }),
    );
  }

  getTreeData() {
    const { data } = this.state;
    if (data == null) {
      return []
    }
    let datasetMap = data.reduce((map, obj) => {
      let key = "/" + obj.groups.concat([obj.datapackage.name]).join("/");
      map[key] = {
        // TODO: include more datapackage field
        name: obj.datapackage.name,
        title: obj.datapackage.title,
        description: obj.datapackage.description,
        dataset_id: obj.datapackage.dataset_id
      };
      return map;
    }, {})

    let heirarchyList = []

    data.forEach((dataset) => {
      if (dataset.datapackage == null) return;
      if (dataset.datapackage.resources == null) return;
      let key_prefix = "/" + dataset.groups.concat([dataset.datapackage.name]).join("/");
      dataset.datapackage.resources.forEach((table) => {
        var key;
        if (table.path.length > 0) {
          key = key_prefix + "/" + table.path[0].split("/")[0] + "/" + table.name;
          heirarchyList.push(
            dataset.groups.concat([dataset.datapackage.name, table.path[0].split("/")[0], table.name])
          )
        } else {
          key = key_prefix + "/<others>/" + table.name;
          heirarchyList.push(
            dataset.groups.concat([dataset.datapackage.name, "<others>", table.name])
          )
        }
        datasetMap[key] = table;
      })
    })

    return arrayListToNodes(heirarchyList, datasetMap);
  }

  onSelection = id => {
    this.setState({
      selected: id,
      items: TreeUtils.toggleItemsToId(this.state.items, id, this.state.selection)
    })
  }

  onItemClick(itemProps, label) {
    let onClickTable = this.props.onClickTable;
    let onClickDataset = this.props.onClickDataset;
    let onEndAddingDataset = this.props.onEndAddingDataset;
    if (itemProps.active) {
      if (itemProps.tableinfo) {
        onClickTable({...itemProps.tableinfo, tableId: itemProps.tableinfo.name}, itemProps);
      } else if (itemProps.datasetinfo) {
        onClickDataset({...itemProps.datasetinfo});
      }
    }
    let url
    if (itemProps.parent) {
      url = `/dataset/${itemProps.parent}/${label}`
    } else {
      url = `/dataset/${label}`
    }
    return () => {
      if (itemProps.tableinfo) {
        this.setState({
          itemProps: itemProps
        })
        navigate(url)
        onClickTable({...itemProps.tableinfo, tableId: itemProps.tableinfo.name}, itemProps);
      }
      else if (itemProps.datasetinfo) {
        this.setState({
          itemProps: itemProps
        })
        navigate(url)
        onClickDataset({...itemProps.datasetinfo});
      } else {
        this.setState({
          itemProps: itemProps
        })
        onEndAddingDataset()
        navigate(url)
      }
    }
  }

  renderDatasetListInfor() {
    let { data, initialActiveKey, initialOpenNodes } = this.state;
    var treeData;
    treeData = this.getTreeData();

    const DEFAULT_PADDING = 16;
    const ICON_SIZE = 8;
    const LEVEL_SPACE = 20;

    const callAll = (...fn) => (...args) => fn.forEach(f => f && f(...args));

    const ToggleIcon = ({ on }) => {
      if (on) return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16" style={{ marginRight: "10px" }}>
          <path fillRule="evenodd" d="M3.204 5L8 10.481 12.796 5H3.204zm-.753.659l4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
        </svg>
      )
      else return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right" viewBox="0 0 16 16" style={{ marginRight: "10px" }}>
          <path fillRule="evenodd" d="M6 12.796L11.481 8 6 3.204v9.592zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
        </svg>
      );
    };

    const ListItemWrapped = ({
        level = 0,
        hasNodes,
        isOpen,
        label,
        searchTerm,
        openNodes,
        toggleNode,
        onClick,
        focused,
        ...props
      }) => (
        <ListItem
          {...props}
          onClick={callAll(onClick, toggleNode, this.onItemClick(props, label))}
          button
          style={{
            paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE,
            cursor: "pointer",
            fontWeight: `${ level == 0 ? "bold" : ""}`,
            backgroundColor: focused ? 'lightBlue' : 'none'
          }}
        >
          {hasNodes && <ToggleIcon on={isOpen} />}
          {label}
        </ListItem>
      );

    return (
      <div className="rows is-full">
        <div
          className="row is-full tabrow"
          style={{
            backgroundColor: 'white', padding: '8px', marginLeft: '15px', height: '90vh', overflowY: 'auto',
          }}
        >
          <TreeMenu
            data={treeData}
            initialOpenNodes={initialOpenNodes}
            initialActiveKey={initialActiveKey}
            initialFocusKey={initialActiveKey}
            debounceTime={125}>
              {({ search, items, toggleNode, hasNodes, onClick, isOpen }) => (
                  <>
                    <input className="input" type="text" style={{ width: "60%"}} onChange={e => search(e.target.value)} placeholder="Search..."></input>
                    <div className="dropdown is-right" id="add-dropdown">
                      <div className="dropdown-trigger">
                        <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={this.dropDownClick}>
                          <span className="icon is-small" style={{ paddingBottom: '8px'}}>
                            <FontAwesomeIcon icon={faPlus} />
                          </span>
                        </button>
                      </div>
                      <div className="dropdown-menu" id="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                          <a href="#" className="dropdown-item" onClick={this.onClickAddDataset}>
                            New dataset
                          </a>
                        </div>
                      </div>
                    </div>
                    <br></br>


                    <List style={{ listStyleType: "none", marginLeft: "-20px", marginTop: "10px", overflow: "auto" }}>
                      {items.map(props => (
                        <ListItemWrapped {...props} />
                      ))}
                    </List>
                  </>
              )}
          </TreeMenu>
        </div>
      </div>
    );
  }


  renderDatasetList() {
    return (
      <div className="rows">
        <div className="row is-full">
          {this.renderDatasetListInfor()}
        </div>
      </div>
    );
  }


  renderError() {
    const messageClass = 'is-danger';
    const errorReason = 'It seems like something went wrong with the DatasetList.';

    const { error } = this.state;

    return (
      <div className="columns is-full">
        <article className={`message ${messageClass}`}>
          <div className="message-body">
            { errorReason }
            <br />
            <i>
              {' '}
              Error:
              { error.message }
              {' '}

            </i>
          </div>
        </article>
      </div>
    );
  }

  render() {
    const { error, isLoading } = this.state;

    if (error) {
      return (
        <div className="section">
          { this.renderError() }
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="columns is-centered">
          <div className="column has-text-centered is-10"
          style={{
            backgroundColor: 'white', padding: '8px', marginLeft: '15px', height: '90vh'
          }}>
            <div className="iframe-holder" />
          </div>
        </div>
      );
    }

    return (
      <div>{ this.renderDatasetList() }</div>
    );
  }
}

export default DatasetList;
