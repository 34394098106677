import React from 'react';
import BaseMetric from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseMetric';
import OverviewSummary from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPortal/OverviewSummary';
import { Link } from "@reach/router";
import TreeMenu, { defaultChildren, ItemComponent } from 'react-simple-tree-menu';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";


class Overview extends BaseMetric {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            date: null,
            data: [],
            page: 0,
        }

        this.logPerPage = 3;
    }

    getStatus() {
        if (this.state.isLoading) {
          return "loading";
        }
        if (this.state.data.length == 0) {
          return "pass"
        } else {
          return "fail"
        }
    }

    getTitle() {
        if (this.props.title) {
          return (
            <Link to="/status/portal"> {this.props.title} </Link>
          )
        } else {
          return "Error Reporting";
        }
    }

    getApiPath() {
        return "status/portal/errors";
    }

    getItemKey(error_message) {
        return `${error_message.error_component}-${error_message.timestamp}`;
    }

    renderErrorList(visibleLogs) {
      let treeData = [];
      let errorDetail = []

      visibleLogs.map(log => {
        errorDetail = []
        if (log.error_detail) {
          log.error_detail.map(err => {
            errorDetail.push({
              "key": err.request_id,
              "label": `${err.time} - Status: ${err.status} - URI: ${err.uri} - Referrer: ${err.referrer} - TimeTaken: ${err.time_taken}s`,
              "nodes": []
            })
          })
        }

        treeData.push({
          "key": this.getItemKey(log),
          "label": `${log.error_component} - ${log.error_type.toString().slice(0, 20)} ${ log.error_type.toString().length > 20 ? '...' : ''} - ${log.message} error(s) at - ${log.timestamp.toLocaleString().slice(0, -6)}`,
          "nodes":  errorDetail
        })
      })

      const DEFAULT_PADDING = 16;
      const ICON_SIZE = 8;
      const LEVEL_SPACE = 20;

      const callAll = (...fn) => (...args) => fn.forEach(f => f && f(...args));

      const ToggleIcon = ({ on }) => {
        if (on) return (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16" style={{ marginRight: "10px" }}>
            <path fillRule="evenodd" d="M3.204 5L8 10.481 12.796 5H3.204zm-.753.659l4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
          </svg>
        )
        else return (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right" viewBox="0 0 16 16" style={{ marginRight: "10px" }}>
            <path fillRule="evenodd" d="M6 12.796L11.481 8 6 3.204v9.592zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
          </svg>
        );
      };

      const ListItemWrapped = ({
          level = 0,
          hasNodes,
          isOpen,
          label,
          searchTerm,
          openNodes,
          toggleNode,
          onClick,
          focused,
          ...props
        }) => (
          <ListItem
            {...props}
            onClick={callAll(onClick, toggleNode)}
            button
            style={{
              paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE,
              cursor: "pointer",
              fontWeight: `${ level == 0 ? "bold" : ""}`,
              backgroundColor: 'none'
            }}
          >
            {hasNodes && <ToggleIcon on={isOpen} />}
            {label}
          </ListItem>
        );

      return (
        <div className="rows is-full">
          <div
            className="row is-full tabrow"
            style={{
              backgroundColor: 'white', padding: '8px', marginLeft: '15px', overflowY: 'auto',
            }}
          >
            <TreeMenu
              data={treeData}
              debounceTime={125}>
                {({ search, items, toggleNode, hasNodes, onClick, isOpen }) => (
                    <>
                      <List style={{ listStyleType: "none", marginLeft: "-20px", marginTop: "10px", overflow: "auto" }}>
                        {items.map(props => (
                          <ListItemWrapped {...props} />
                        ))}
                      </List>
                    </>
                )}
            </TreeMenu>
          </div>
        </div>
      );
    }


    renderLogs(visibleLogs) {
        if (visibleLogs.length == 0) {
            return (
                <div>
                    <p className="header">No errors.</p>
                </div>
            )
        } else {
            return (
                <div>
                    {this.renderErrorList(visibleLogs)}
                </div>
            )
        }
    }

    renderPagination(validLogs) {
        if (validLogs.length <= this.logPerPage ) {
            return (<div></div>)
        }
        let maxPage = Math.ceil(validLogs.length / this.logPerPage);

        return (
            <div style={{marginTop: '12px'}}>
                <nav className="pagination is-small is-centered">
                    <a
                        className="pagination-previous"
                        onClick={() => {
                            if (this.state.page > 0) {
                                this.setState({page: this.state.page - 1})}
                            }
                        }
                    >Previous</a>
                    <a
                        className="pagination-next"
                        onClick={() => {
                            if (this.state.page < maxPage -1) {
                                this.setState({page: this.state.page + 1})}
                            }
                        }
                    >Next</a>
                    <ul className="pagination-list">
                        {
                            [...Array(maxPage).keys()].map(
                                (k) => (
                                    <li className={
                                            (k == this.state.page) ?
                                            "pagination-link is-current"
                                            : "pagination-link"
                                        }
                                            onClick={() => this.setState({page: (k)})}
                                        >{(k + 1).toString()}
                                    </li>
                                )
                            )
                        }
                    </ul>
                </nav>
            </div>
        )
    }

    renderMetric() {
        let visibleLogs = this.state.data.slice(
            (this.state.page) * this.logPerPage, (this.state.page + 1) * this.logPerPage
        )
        return (
            <div>
                { this.renderLogs(visibleLogs) }
                { this.renderPagination(this.state.data) }
            </div>
        )
    }
}


export default Overview
