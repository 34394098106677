// Only env variables that have GATSBY_ prefix will be available on client scripts
const C360_API_URL = process.env.GATSBY_API_URL || 'https://api.ulph.c360.ai';
const REQUIRES_LOGIN = process.env.REQUIRES_LOGIN || 1;

module.exports = {
  siteTitle: 'c360 – ulph', // Site title.
  siteTitleAlt: 'c360', // Alternative site title for SEO.
  siteUrl: new URL('https://ulph.c360.ai'),
  description: '360 view of ulph\'s customers',
  copyright: 'Copyright © TRA 2021. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  googleTagManagerID: '', // Remember to replace it later with Tenant's
  googleAnalyticsTrackingID: 'UA-112500065-2', // Remember to replace it later with Tenant's
  apiUrl: C360_API_URL,
  pipelineJson: `data/pipeline`,
  pipelinePageJson: `data/list_pipeline.json`,
  requiresLogin: (REQUIRES_LOGIN == 1),
};
