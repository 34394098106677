import React from 'react';
import BaseMetric from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseMetric';
import { Link } from 'gatsby';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


class ErrorReporting extends BaseMetric {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            date: null,
            data: [],
            page: 0,
            errorType: ["Open", "InProgress"],
            updatingStatus: false
        }

        this.logPerPage = 3;
    }

    getStatus() {
        let openTicket = this.state.data.filter(function (e) {
            return e.status == "Open" || e.status == "InProgress";
        });
        if (this.state.isLoading) {
          return "loading";
        }
        if (openTicket.length == 0) {
          return "pass"
        } else {
          return "fail"
        }
    }

    getTitle() {
        if (this.props.title) {
          return (
            <Link to="/status/pipelines"> {this.props.title} </Link>
          )
        } else {
          return "Error Tickets";
        }
    }

    getApiPath() {
        return "status/pipelines/tickets";
    }

    getItemKey(ticket) {
        return `${ticket.opsItemId}`;
    }

    changeFiltered(filteredValue) {
      let filteredList = this.state.errorType
      if (this.state.errorType.includes(filteredValue)) {
        filteredList = filteredList.filter(function(item) {
            return item !== filteredValue
        })
      } else {
        filteredList.push(filteredValue)
      }
      this.setState({
        errorType: filteredList
      })
    }

    renderFilter() {
      return (
        <div style={{marginTop: '10px'}}>
          <input type="checkbox" id="filter" name="filter" value="inprogress" defaultChecked onChange={() => this.changeFiltered("InProgress")}></input>
          <label>&nbsp; In Progress &nbsp;</label>
          <input type="checkbox" id="filter" name="filter" value="open" defaultChecked onChange={() => this.changeFiltered("Open")}></input>
          <label>&nbsp; Open &nbsp;</label>
          <input type="checkbox" id="filter" name="filter" value="resolved" onChange={() => this.changeFiltered("Resolved")}></input>
          <label>&nbsp; Resolved &nbsp;</label>
        </div>
      )
    }

    async markAs(type, opsItemId) {
      this.setState({
        updatingStatus: true
      })
      const callApiUrl = `status/pipelines/tickets/${type}/${opsItemId}`;
      await callApi(
        callApiUrl,
        (result) => {
          const loadedResult = result.data;
          if (loadedResult == null) throw Error('Performance is not succeeded.');
          if (loadedResult === []) {
            // no permission to data
            this.setState({
              error: null,
            });
          } else {
            this.setState({
              loadedResult: loadedResult
            })
          }
        (error) => this.setState({ error })}
      );
      if (this.state.loadedResult.includes("Successfully")) {
        this.setState({
          updatingStatus: false
        })
        window.alert(`ticket ID ${opsItemId} has been marked as ${type}!`);
        location.reload()
      }
    }

    parseDate(str_date) {
      return new Date(Date.parse(str_date));
    }

    getStatusColor(status) {
      if (status == "Open") {
        return { color: "Red" }
      } else if (status == "InProgress") {
        return { color: "Yellow" }
      } else {
        return { color: "Green" }
      }
    }

    renderLogs(visibleLogs) {
        if (visibleLogs.length == 0) {
            return (
                <div>
                    { this.renderFilter() }
                    <p className="header">No tickets.</p>
                </div>
            )
        } else {
            return (
                <div>
                    { this.renderFilter() }
                    <ul>
                        {
                            visibleLogs.map(
                                (e) => (<li key={this.getItemKey(e)}><p>
                                    <a href={`https://ap-southeast-1.console.aws.amazon.com/systems-manager/opsitems/${e.opsItemId}/`}><strong>{e.type}</strong> </a>
                                    - <strong>Status</strong>: <text style={this.getStatusColor(e.status)}>{e.status}</text>
                                    - <strong>Category</strong>: {e.category}
                                    - <strong>Severity</strong>: {e.severity}
                                    - <strong>Message</strong>: {e.title.slice(0, 100)}
                                    { e.title.length > 100 ? '...' : ''}
                                    - <strong>Created</strong>: {this.parseDate(e.createdTime).toLocaleString()}
                                    - <strong>lastModified</strong>: {this.parseDate(e.lastModifiedTime).toLocaleString()}
                                    &nbsp;
                                    <button className={`button ${this.state.updatingStatus ? 'is-loading' : ""}`} aria-haspopup="true" aria-controls="dropdown-menu" style={{marginTop: "-3px", height: "80%", fontSize: "12px"}} disabled={e.status == 'InProgress' ? 'True' : ''} onClick={() => this.markAs("inprogress", e.opsItemId)}>
                                      In Progress
                                    </button>&nbsp;
                                    <button className={`button ${this.state.updatingStatus ? 'is-loading' : ""}`} aria-haspopup="true" aria-controls="dropdown-menu" style={{marginTop: "-3px", height: "80%", fontSize: "12px"}} disabled={e.status == 'Open' ? 'True' : ''} onClick={() => this.markAs("open", e.opsItemId)}>
                                      Open
                                    </button>&nbsp;
                                    <button className={`button ${this.state.updatingStatus ? 'is-loading' : ""}`} aria-haspopup="true" aria-controls="dropdown-menu" style={{marginTop: "-3px", height: "80%", fontSize: "12px"}} disabled={e.status == 'Resolved' ? 'True' : ''} onClick={() => this.markAs("resolved", e.opsItemId)}>
                                      Resolved
                                    </button>&nbsp;
                                </p></li>)
                            )
                        }
                    </ul>
                </div>
            )
        }
    }

    renderPagination(validLogs) {
        if (validLogs.length <= this.logPerPage ) {
            return (<div></div>)
        }
        let maxPage = Math.ceil(validLogs.length / this.logPerPage);

        return (
            <div style={{marginTop: '12px'}}>
                <nav className="pagination is-small is-centered">
                    <a
                        className="pagination-previous"
                        onClick={() => {
                            if (this.state.page > 0) {
                                this.setState({page: this.state.page - 1})}
                            }
                        }
                    >Previous</a>
                    <a
                        className="pagination-next"
                        onClick={() => {
                            if (this.state.page < maxPage -1) {
                                this.setState({page: this.state.page + 1})}
                            }
                        }
                    >Next</a>
                    <ul className="pagination-list">
                        {
                            [...Array(maxPage).keys()].map(
                                (k) => (
                                    <li className={
                                            (k == this.state.page) ?
                                            "pagination-link is-current"
                                            : "pagination-link"
                                        }
                                            onClick={() => this.setState({page: (k)})}
                                        >{(k + 1).toString()}
                                    </li>
                                )
                            )
                        }
                    </ul>
                </nav>
            </div>
        )
    }

    renderMetric() {
        let { errorType, data } = this.state
        let filteredData = data.filter(function (e) {
            return errorType.includes(e.status);
        });
        let visibleLogs = filteredData.slice(
            (this.state.page) * this.logPerPage, (this.state.page + 1) * this.logPerPage
        )
        return (
            <div>
                { this.renderLogs(visibleLogs) }
                { this.renderPagination(data) }
            </div>
        )
    }
}


export default ErrorReporting
