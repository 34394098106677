import DatalakePipelinesDiagram from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakePipelinesDiagram';
import PipelineStatus from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/PipelineStatus';
import React from 'react';
import { Link } from 'gatsby';


class TablePipeline extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    let { tableData, isLoading, date } = this.props

    if (isLoading) {
      return (
        <div className="columns is-centered">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      );
    }

    if (tableData) {
      if (tableData.pipeline_id) {
        return (
          <div className="columns">
            <div className="column is-2">
              <p className="has-text-weight-bold is-size-6">{tableData.pipeline_id}</p>
              <p className="has-text-weight-light is-size-7">
                Date:
                {' '}
                {date}
              </p>
              <p className="has-text-weight-light is-size-7">Status: {<PipelineStatus date={date} pipeline_name={tableData.pipeline_id} />}</p>
              <Link className="button" to={`/status/pipeline/${tableData.pipeline_id}/${date}`}>Detail</Link>
            </div>
            <div className="column is-10">
              <DatalakePipelinesDiagram date={date} pipeline_name={tableData.pipeline_id} />
            </div>
          </div>
        );
      } else {
        return (
          <div className="columns">
            <div className="column is-narrow">
              Pipeline info is not available
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="columns">
          <div className="column is-narrow">
            Pipeline info is not available
          </div>
        </div>
      );
    }
  }
}

export default TablePipeline;
