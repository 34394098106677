import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { pipelineJson } from '@tra-sg/gatsby-theme-c360-portal/src/data/config';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


export default class PipelineStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      pipeline: null
    };
  }


  fetchData() {
    const { date } = this.props;
    const { pipeline_name } = this.props;
    let callApiUrl = `${pipelineJson}_${pipeline_name}_${date}.json`

    this.setState({ isLoading: true });

    callApi(
      callApiUrl,
      (result) => {
        let loaded_pipeline = []
        loaded_pipeline = result.pipeline;

        if (loaded_pipeline == null) throw Error('Invalid pipeline info received.');
        this.setState({
          isLoading: false,
          error: null,
          pipeline: loaded_pipeline
        });
      },
      (error) => this.setState({ error, isLoading: false })
    )
  }


  componentDidMount() {
    this.fetchData();
  }


  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date || this.props.pipeline_name !== prevProps.pipeline_name) {
      this.fetchData();
    }
  }


  render() {
    const { error, isLoading, pipeline } = this.state;
    let pipeline_status = "SUCCEEDED"
    if (error) {
      return (
        <div>
          Not Available
        </div>
      );
    }

    if (isLoading) {
      return (
        <div>
          <div className="iframe-holder" />
        </div>
      );
    }


    // example schema for "pipeline" json
    // {
    //   "dataset_table_id": "t200_main",
    //   "source": [
    //     {
    //       "table_id": "t100_main",
    //       "date": "2020-07-27"
    //     }
    //   ],
    //   "status": "CANCELLED",
    //   "date": "2020-07-27",
    //   "execution_time": null,
    //   "execution_date": null,
    //   "upper_level": "main_group",
    //   "row_count": 0,
    //   "healthchecks": []
    // }
    this.state.pipeline.forEach(
      (pipeline_step) => {
        const { status, date, dataset_table_id } = pipeline_step;
        if (status == 'FAILED') {
          pipeline_status = status
          return (
            <div>
              { pipeline_status }
            </div>
          );
        }

        if (!dataset_table_id.includes("_waiter") && !dataset_table_id.includes("bq_") && status == 'CANCELLED') {
          pipeline_status = status
        }
      }
    );

    return (
      <div>
        { pipeline_status }
      </div>
    );
  }
}
