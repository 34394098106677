import React from 'react';
import BaseMetric from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseMetric';


class InfraErrorReporting extends BaseMetric {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            date: null,
            data: [],
            page: 0,
        }

        this.logPerPage = 3;
    }

    getStatus() {
        if (this.state.isLoading) {
          return "loading";
        }
        if (this.state.data.length == 0) {
          return "pass"
        } else {
          return "fail"
        }
    }

    getTitle() {
        return "Error Reporting";
    }

    getApiPath() {
        return "status/infra/errors";
    }

    getItemKey(error_message) {
        return `${error_message.pipeline}-${error_message.message}`;
    }

    renderLogLine(log_item) {
        return (
            <li key={this.getItemKey(log_item)}>
                <p>
                    {
                        (log_item.resource_url > "")
                        ?
                        (<a href={log_item.resource_url}>
                            <strong> {log_item.resource} </strong>
                        </a>)
                        :
                        (<strong> {log_item.resource} </strong>)
                    }
                    - {log_item.message.slice(0, 100)}
                    { log_item.message.length > 100 ? '...' : ''}
                    { log_item.log_url ? (<span> (<a href={log_item.log_url}>logs</a>) </span>) : " " }
                    {
                        log_item.timestamp ?
                        (<span className="has-text-weight-light is-italic is-size-7">
                            {new Date(log_item.timestamp).toString().split('GMT')[0]}
                        </span>)
                        :
                        ""
                    }
                </p>
            </li>
        )
    }

    renderLogs(visibleLogs) {
        if (visibleLogs.length == 0) {
            return (
                <div>
                    <p className="header">No errors.</p>
                </div>
            )
        } else {
            return (
                <div>
                    <ul>
                        { visibleLogs.map((e) => (this.renderLogLine(e))) }
                    </ul>
                </div>
            )
        }
    }

    renderPagination(validLogs) {
        if (validLogs.length <= this.logPerPage ) {
            return (<div></div>)
        }
        let maxPage = Math.ceil(validLogs.length / this.logPerPage);

        return (
            <div style={{marginTop: '12px'}}>
                <nav className="pagination is-small is-centered">
                    <a
                        className="pagination-previous"
                        onClick={() => {
                            if (this.state.page > 0) {
                                this.setState({page: this.state.page - 1})}
                            }
                        }
                    >Previous</a>
                    <a
                        className="pagination-next"
                        onClick={() => {
                            if (this.state.page < maxPage -1) {
                                this.setState({page: this.state.page + 1})}
                            }
                        }
                    >Next</a>
                    <ul className="pagination-list">
                        {
                            [...Array(maxPage).keys()].map(
                                (k) => (
                                    <li className={
                                            (k == this.state.page) ?
                                            "pagination-link is-current"
                                            : "pagination-link"
                                        }
                                            onClick={() => this.setState({page: (k)})}
                                        >{(k + 1).toString()}
                                    </li>
                                )
                            )
                        }
                    </ul>
                </nav>
            </div>
        )
    }

    renderMetric() {
        let visibleLogs = this.state.data.slice(
            (this.state.page) * this.logPerPage, (this.state.page + 1) * this.logPerPage
        )
        return (
            <div>
                { this.renderLogs(visibleLogs) }
                { this.renderPagination(this.state.data) }
            </div>
        )
    }
}


export default InfraErrorReporting
