import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import QuickLook from '@tra-sg/gatsby-theme-c360-portal/src/components/QuickLook';
import React from 'react';
import _ from 'lodash';


function sortByKey(array, key) {
  return array.sort((a, b) => {
    const x = a[key]; const y = b[key];
    if (x === y) {
      return 0;
    } if (x < y) {
      return -1;
    }
    return 1;
  });
}

function generateMockData(featuresMape) {

  if (featuresMape == null) {
    featuresMape = {
      "Baseline": 30.2, "Grouped Forecast": 19.3, "Holiday": 18.7, "Grouped Forecast + Holiday": 5.4
    }
  }

  let actualData = [
    {"date": "2020-12-28", "actual": 22098,},
    {"date": "2020-12-29", "actual": 20155,},
    {"date": "2020-12-30", "actual": 19878,},
    {"date": "2020-12-31", "actual": 21788,},
    {"date": "2021-01-01", "actual": 22098,},
    {"date": "2021-01-02", "actual": 24068,},
    {"date": "2021-01-03", "actual": 24556,},
    {"date": "2021-01-04", "actual": 24512,},
    {"date": "2021-01-05", "actual": 21099,},
    {"date": "2021-01-06", "actual": 22098,},
    {"date": "2021-01-07", "actual": 26889,},
  ]



  var AllFeatureData = {};

  for (const [feature, mape] of Object.entries(featuresMape)) {
    var featureData = actualData.map(
      function(e) {
        if (e.date < "2020-12-31") return e;
        if (e.date == "2020-12-31") return {...e, pred: e.actual, pred_band: [e.actual, e.actual]};
        let pred = e.actual * (1 + ((Math.random() - 0.5) * 2 * mape / 100));
        let pred_band = [pred * (1 - mape / 100), pred * (1 + mape / 100)];
        return {
          ...e,
          pred: pred,
          pred_band: pred_band,
        }
      }
    )

    AllFeatureData[feature] = {
      mape: mape,
      forecast: featureData
    }
  }

  return {
    default: _.minBy(Object.entries(featuresMape), (e) => { return e[1] })[0],
    features: AllFeatureData,
  }
}



class SalesForecastFeatureBreakdown extends React.Component {
  constructor(props) {
    super(props);

    let featuresMape = this.props.featuresMape;
    let data = generateMockData(featuresMape);
    this.state = {
      selectedFeature: data.default,
      data: data,
    }

    this.setSelectedFeature = this.setSelectedFeature.bind(this);
  }

  setSelectedFeature(feature) {
    this.setState({
      selectedFeature: feature,
    })
  }

  getListofFeatures() {
    let { data } = this.state;
    return Object.keys(data.features)
  }

  getDataofFeature(featureName) {
    let { data } = this.state;
    return data.features[featureName].forecast;
  }

  getDefaultFeature() {
    let { data } = this.state;
    return data.default;
  }

  getScore(featureName) {
    let { data } = this.state;
    return data.features[featureName].mape;

  }

  renderChart() {
    let { selectedFeature } = this.state;
    let tooltip = "Also known as Mean Absolute Percentage Error (MAPE), this is the measure of how big is the difference between forecast and actual. Good forecast will have lower number."
    let subtitle = (
      <span>
        Error Gap: {this.getScore(selectedFeature)}%
        <span className="icon is-small has-tooltip-multiline" data-tooltip={tooltip}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      </span>
    )
    return (
      <QuickLook
        subtitle={subtitle}
        chartType="line_w_band"
        data={this.getDataofFeature(selectedFeature)}
        X="date"
        Y={["pred_band", "actual", "pred"]}
        colorWheel={[
        "#dddddd",
        "#00b159",
        "#f37735",
        ]}
        disableColorOffset
      />
    )
  }

  renderFeatureButton(feature) {
    let { selectedFeature } = this.state;
    let buttonClass = ((feature == selectedFeature) ? "button is-info" : "button");
    let onClick = () => this.setSelectedFeature(feature);

    var innerContent;
    if(feature == this.getDefaultFeature()) {
      innerContent = (<span><FontAwesomeIcon icon={faMedal}/> {feature}</span>)
    } else {
      innerContent = (<span>{feature}</span>)
    }

    return (
      // both control and buttons need 100%
      <p className="control" style={{width: '100%'}}>
        <a className={buttonClass} onClick={onClick} style={{width: '100%'}}>
          {innerContent}
        </a>
      </p>
    )
  }

  renderFeatureControls() {
    return (
      <div>
        <div className="subtitle">
          {this.props.name || 'Feature Set'}
        </div>
        <div className="field is-grouped is-grouped-multiline">
          {this.getListofFeatures().map((e) => this.renderFeatureButton(e))}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="columns">
        <div className="column is-two-thirds has-text-centered">
          {this.renderChart()}
        </div>
        <div className="column is-one-third">
          {this.renderFeatureControls()}
        </div>

      </div>
    )
  }
}

export default SalesForecastFeatureBreakdown;
