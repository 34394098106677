import { faDiceD6 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatalakePipelinesDiagram from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakePipelinesDiagram';
import QuickLook from '@tra-sg/gatsby-theme-c360-portal/src/components/QuickLook';
import TablePreview from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TablePreview';
import TableQuery from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TableQuery';
import TablePipeline from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TablePipeline';
import TablePipelineInfor from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TablePipelineInfor';
import TableInOutPut from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TableInOutPut';
import TableDataExploration from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TableDataExploration';
import UserTablePreview from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/UserTablePreview';
import UserTableSchema from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/UserTableSchema';
import TableOverview from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TableOverview';
import NewNotebook from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/NewNotebook';
import DatasetInfo from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/DatasetInfo';
import React from 'react';
import DatePicker from 'react-date-picker';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DatasetRegistration from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/DatasetRegistration';
import DatasetList from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/DatasetList';
import { navigate } from "@reach/router";
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


class DatalakeBrowser extends React.Component {
  constructor(props) {
    super(props);

    const date = [
      (new Date()).getFullYear(),
      (`0${(new Date()).getMonth() + 1}`).slice(-2),
      (`0${(new Date()).getDate()}`).slice(-2),
    ].join('');

    const datePicker = new Date(
      date.substring(0, 4), date.substring(4, 6) - 1, date.substring(6, 8),
    );

    this.state = {
      datePicker,
      date,
      tableId: null,
      tableinfo: null,
      datasetInfo: null,
      datasetId: null,
      error: null,
      tableError: null,
      data: null,
      tableData: null,
      isLoading: true,

      //permission selection
      selectedOption: null,

      // determine whether add dataset or table
      addingDataset: null,
      addingTable: null,


    };
    this.onChange = this.onChange.bind(this);
    this.updateTableInfo = this.updateTableInfo.bind(this);
    this.updateDatasetInfo = this.updateDatasetInfo.bind(this);
    this.endAddingDataset = this.endAddingDataset.bind(this);
  }


  componentDidMount() {
    this.fetchTableData();
  }


  fetchTableData(tableinfo) {
    let zone_id = this.getZoneIdFromURL()
    let dataset_id = this.getDatasetIdFromURL()
    let table_id = tableinfo ? tableinfo.name : null

    if (zone_id && dataset_id && table_id) {
      const callApiUrl = `dataset/table/pipeline_info?dataset=${dataset_id}&zone=${zone_id}&table=${table_id}`
      this.setState({ isLoading: true });

      callApi(
        callApiUrl,
        (result) => {
          const loadedResult = result.data;
          if (loadedResult == null) {
            throw Error('Invalid table info received.');
          }
          if (loadedResult === []) {
            this.setState({
              tableError: {message: "Error Loading Table Info"},
              tableId: table_id,
              isLoading: false,
            });
          } else {
            this.setState({
              tableError: null,
              tableData: loadedResult,
              tableId: table_id,
              isLoading: false,
            });
          }
        },
        (tableError) => this.setState({ tableError, isLoading: false }),
      );
    }
  }


  onChange(datePicker) {
    const ddate = [
      datePicker.getFullYear(),
      (`0${datePicker.getMonth() + 1}`).slice(-2),
      (`0${datePicker.getDate()}`).slice(-2),
    ].join('');

    this.setState({
      datePicker,
      date: ddate,
    });
  }


  onClickAddDataset = () => {
    this.setState({ addingDataset: true, addingTable: null })
  }

  addDefaultTabToURL(url) {
    if (url) {
      if (url.substring(url.length-1) == "/") {
        url = `${url.substring(0, url.length-1)}/?tab=overview`;
      }
    }
    return url;
  }

  getDatasetIdFromURL() {
    let url = window.location.href
    url = this.addDefaultTabToURL(url)
    if (url.includes("/?tab=")) {
      url = url.split("/?tab")[0]
    }
    return url.split("/")[url.split("/").length - 3]
  }


  getZoneIdFromURL() {
    let url = window.location.href
    url = this.addDefaultTabToURL(url)
    if (url.includes("/?tab=")) {
      url = url.split("/?tab")[0]
    }
    return url.split("/")[url.split("/").length - 2]
  }

  getGroupIdFromURL() {
    url = this.addDefaultTabToURL(url)
    let url = window.location.href
    if (url.includes("/?tab=")) {
      url = url.split("/?tab")[0]
    }
    return url.split("/")[url.split("/").length - 4]
  }

  disabledIfNotCommonDataset() {
    let url = window.location.href
    if (url.includes("dataset/common")) {
      return false
    } else {
      return true
    }
  }

  renderPipelineInfor() {
    const { tableData, isLoading, date } = this.state;
    return (
      <TablePipelineInfor tableData={tableData} isLoading={isLoading} date={date}/>
    );
  }


  async downloadTable(dataset_id, zone_id, table_id, group_id) {
    let { error } = this.state
    this.setState({
      generatingPresignedUrl: true
    })
    const callApiUrl = `dataset/table/get_presigned_url?dataset=${dataset_id}&zone=${zone_id}&table=${table_id}&groups=${group_id}`;
    await callApi(
      callApiUrl,
      (result) => {
        const presignedUrl = result.presigned_url;
        if (presignedUrl === []) {
          // no permission to data
          this.setState({
            error: null,
          });
        } else {
          this.setState({
            presignedUrl: presignedUrl,
            generatingPresignedUrl: false
          })
        }
      (error) => this.setState({ error })}
    );
    if (error) {
      window.alert("Error occured!")
    } else if (!this.state.presignedUrl) {
      window.alert("Not available!")
    } else {
      navigate(this.state.presignedUrl, '_blank')
    }
  }


  renderTableHeader() {
    const { datePicker, tableId, isLoading } = this.state;
    let zone_id = this.getZoneIdFromURL()
    let dataset_id = this.getDatasetIdFromURL()
    let table_id = tableId
    let group_id = this.getGroupIdFromURL()

    return (
      <div className="columns">
        <div className="column is-four-fifths">
          <h4 className="title">{tableId} &nbsp;
            <button
                className={`button ${this.state.generatingPresignedUrl ? 'is-loading' : ""}`}
                aria-haspopup="true" aria-controls="dropdown-menu"
                onClick={() => this.downloadTable(dataset_id, zone_id, table_id, group_id)}
                style={{ fontSize: "10px" }}
              >
              <FontAwesomeIcon icon={faDownload} />
            </button>
          </h4>
        </div>
        <div className="column is-one-fifth">
          <div className="">
            <DatePicker
              onChange={this.onChange}
              value={datePicker}
              clearIcon={null}
              maxDate={new Date()}
            />
          </div>
        </div>
      </div>
    );
  }


  renderTableSchema() {
    const { tableData, isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="columns half-vh">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      )
    }

    if (tableData) {
      if ((tableData.schema || []).length == 0) {
        return (
          <div className="columns half-vh">
            Schema is not available
          </div>
        );
      }

      if ((tableData.schema || []).length > 0) {
        return (
          <div className="columns half-vh is-mobile">
            <div className="column">
              <h6>Column</h6>
              {
                    tableData.schema.map((column) => (
                      <p className="has-text-weight-light is-size-7" key={column.column_name}>{column.column_name}</p>
                    ))
                  }
            </div>
            <div className="column">
              <h6>Datatype</h6>
              {
                    tableData.schema.map((column) => (
                      <p className="has-text-weight-light is-size-7" key={column.column_name}>{column.data_type}</p>
                    ))
                  }
            </div>
          </div>
        );
      }

      return (
        <div className="columns half-vh">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      )
    }


    return (
      <div className="columns half-vh">
        Schema is not available
      </div>
    );
  }

  handleChange = value => {
    this.setState({
      tableOverviewDescription: value
    });
  };

  renderTableOverview() {
    const { tableinfo, date, tableData, isLoading, tableId, isEditing } = this.state;

    if (isLoading) {
      return (
        <div className="columns half-vh">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      )
    }

    if (tableId) {
      return (
        <TableOverview tableId={tableId} isLoading={isLoading} isEditing={false} />
      )
    } else {
      return (
        <div className="columns half-vh is-mobile">
          Table overview is not available
        </div>
      );
    }
  }

  convertTableSize(size) {
    // ref https://stackoverflow.com/a/20732091
    var i = size == 0 ? 0 : Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  parseDate(dateString) {
    // this is due to the dataresource.json saving date as a string of YYYYMMDD_HHMMSS
    // which does not conform to frictionless data's date format
    // (see https://specs.frictionlessdata.io/data-package/#created)
    // TODO: fix the dataresource.json to save the correct format
    // then we don't need this anymore
    if (dateString) {
      let str_date = `${dateString.substring(0,4)}-${dateString.substring(4,6)}-${dateString.substring(6,8)} ${dateString.substring(9,11)}:${dateString.substring(11,13)}:${dateString.substring(13,15)} UTC`
      return (new Date(Date.parse(str_date))).toLocaleString();
    } else {
      return `Not available`
    }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  renderTableDetail() {
    const { tableinfo, date, tableData, isLoading, tableId } = this.state;

    if (tableinfo) {
      return (
        <div className="columns half-vh is-mobile">
          <div className="column is-narrow">
            <p className="has-text-weight-bold is-size-7">Size:</p>
            <p className="has-text-weight-bold is-size-7">Row count:</p>
            <p className="has-text-weight-bold is-size-7">Last modified:</p>
            <p className="has-text-weight-bold is-size-7">Input tables:</p>
            <p className="has-text-weight-bold is-size-7">Output tables:</p>
          </div>
          <div className="column">
            <p className="has-text-weight-light is-size-7">{tableinfo.bytes ? this.convertTableSize(tableinfo.bytes) : "Not available"}</p>
            <p className="has-text-weight-light is-size-7">{tableinfo.rows ? this.numberWithCommas(tableinfo.rows) : "Not available"}</p>
            <p className="has-text-weight-light is-size-7">{tableinfo.lastModified ? this.parseDate(tableinfo.lastModified) : "Not available"}</p>
            <TableInOutPut tableData={tableData} isLoading={isLoading}/>
          </div>
        </div>
      );
    } else {
      return (
        <div className="columns half-vh is-mobile">
          Table detail is not available
        </div>
      );
    }
  }


  renderTablePreview() {
    const { tableinfo, tableData, isLoading } = this.state;

    if (!tableData) {
      return (
        <div className="columns half-vh">
          <div className="column">
            Preview is not available.
          </div>
        </div>
      );
    }

    if ((tableinfo.path || []).length > 0) {
      if (tableinfo.path[0].includes('confidential/')) {
        return (
          <div className="columns half-vh">
            <div className="column">
              <p>
                Preview is not available for tables in
                <strong> confidential </strong>
                datasets
              </p>
              <p>Please visit athena to see the preview, and enter the following query</p>
              <p>
                <code>
                  SELECT * FROM ulph_c360_lake__
                  {this.getZoneIdFromURL() + "_" + this.getDatasetIdFromURL()}
                  .
                  {tableinfo.name}
                  _latest
                  LIMIT 100
                </code>
              </p>
              <a className="button" href="https://ap-southeast-1.console.aws.amazon.com/athena/home?region=ap-southeast-1#query" id="logout-button"> Visit Athena </a>
            </div>
          </div>
        );
      } else {
        if (tableinfo.schema && tableinfo.schema.fields) {
          return (
            <div className="columns half-vh">
              <div className="column">
                <TablePreview table_id={tableinfo.name} dataset_id={this.getDatasetIdFromURL()} zone_id={this.getZoneIdFromURL()} schema={tableinfo.schema.fields}/>
                <p>
                  For a complete view, please visit athena to see the preview, and
                  enter the following query
                </p>
                <p>
                  <code>
                    SELECT * FROM ulph_c360_lake__
                    {this.getZoneIdFromURL() + "_" + this.getZoneIdFromURL()}
                    .
                    {tableinfo.name}
                    _latest
                    LIMIT 100
                  </code>
                </p>
                <a className="button" href="https://ap-southeast-1.console.aws.amazon.com/athena/home?region=ap-southeast-1#query" id="logout-button"> Visit Athena </a>
                <NewNotebook table_id={tableinfo.name} />
              </div>
            </div>
          );
        } else {
          return (
            <div className="columns half-vh">
              <div className="column">
                Preview is not available.
              </div>
            </div>
          );
        }
      }
    }
  }


  renderTableQuery() {
    const { tableinfo, tableData, isLoading } = this.state;
    if (tableinfo && tableData) {
      return (
        <TableQuery tableData={tableData} isLoading={isLoading}/>
      );
    }
    return (
      <div className="columns half-vh">
        <div className="column">
          Query is not available.
        </div>
      </div>
    );
  }


  renderTablePipeline() {
    const { tableinfo, tableData, isLoading, date } = this.state;
    if (tableinfo && tableData) {
      return (
        <TablePipeline tableData={tableData} isLoading={isLoading} date={date}/>
      );
    }
    return (
      <div className="columns half-vh">
        <div className="column">
          Table Pipeline info is not available.
        </div>
      </div>
    );
  }


  renderTableHistory() {
    const { error, tableinfo } = this.state;
    if (error) {
      // This section is mainly only so that the method uses `this`, which is
      // otherwise a lint error. Once this is unmocked, `this` will naturally
      // be used so this might not be relevant anymore.
      // TODO: remove once unmocked.
      return (
        <div>
          Table history can not be displayed; an error occured:
          {error}
          .
        </div>
      );
    }

    if (tableinfo) {
      return (
        <div className="columns half-vh">
          <div className="column is-narrow">
            <h6>State</h6>
            <p className="has-text-weight-light is-size-7">SUCCEEDED</p>
            <p className="has-text-weight-light is-size-7">SUCCEEDED</p>
            <p className="has-text-weight-light is-size-7">SUCCEEDED</p>
          </div>
          <div className="column is-narrow">
            <h6>Start time</h6>
            <p className="has-text-weight-light is-size-7">2020-09-08 11:00 GMT+8</p>
            <p className="has-text-weight-light is-size-7">2020-09-07 11:00 GMT+8</p>
            <p className="has-text-weight-light is-size-7">2020-09-06 11:00 GMT+8</p>
          </div>
          <div className="column is-narrow">
            <h6>Duration (seconds)</h6>
            <p className="has-text-weight-light is-size-7">120</p>
            <p className="has-text-weight-light is-size-7">119</p>
            <p className="has-text-weight-light is-size-7">108</p>
          </div>
          <div className="column is-half">
            <div className="rows">
              <div className="row-is-full">
                <QuickLook
                  chartType="line"
                  apiPath="data/datalake/size_increase.json"
                  X="date"
                  Y={['size']}
                  chartHeight={140}
                />
              </div>
              <div className="row-is-full">
                <QuickLook
                  chartType="line"
                  apiPath="data/datalake/size_increase.json"
                  X="date"
                  Y={['row']}
                  chartHeight={140}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="columns half-vh">
          History is not available
        </div>
      );
    }
  }


  renderUserTablePreview() {
    const { tableinfo, tableData, isLoading, date } = this.state;
    let group_id = this.getGroupIdFromURL()
    let zone_id = this.getZoneIdFromURL()
    let dataset_id = this.getDatasetIdFromURL()
    let table_id = tableinfo ? tableinfo.name : null

    if (tableinfo) {
      return (
        <div style={{overflow: "auto"}}>
          <UserTablePreview dataset_id={dataset_id} table_id={table_id} zone_id={zone_id} group_id={group_id} isLoading={isLoading} date={date}/>
        </div>
      );
    }
    return (
      <div className="columns half-vh">
        <div className="column">
          Table Preview is not available at the moment. Please come back later.
        </div>
      </div>
    );
  }


  renderUserTableSchema() {
    const { tableinfo, tableData, isLoading, date } = this.state;
    let group_id = this.getGroupIdFromURL()
    let zone_id = this.getZoneIdFromURL()
    let dataset_id = this.getDatasetIdFromURL()
    let table_id = tableinfo ? tableinfo.name : null

    if (tableinfo) {
      return (
        <UserTableSchema dataset_id={dataset_id} table_id={table_id} zone_id={zone_id} group_id={group_id} isLoading={isLoading} date={date}/>
      );
    }
    return (
      <div className="columns half-vh">
        <div className="column">
          Table Schema is not available at the moment. Please comeback later
        </div>
      </div>
    );
  }


  renderTableDataExploration() {
    const { tableinfo, tableData, isLoading, date } = this.state;
    let group_id = this.getGroupIdFromURL()
    let zone_id = this.getZoneIdFromURL()
    let dataset_id = this.getDatasetIdFromURL()
    let table_id = tableinfo ? tableinfo.name : null

    if (tableinfo) {
      return (
        <TableDataExploration dataset_id={dataset_id} table_id={table_id} zone_id={zone_id} group_id={group_id} isLoading={isLoading} date={date}/>
      );
    }
    return (
      <div className="columns half-vh">
        <div className="column">
          Data Exploration is not available.
        </div>
      </div>
    );
  }


  navigateTab(tabIndex, tabDict) {
    let url = window.location.href.split("?")[0]
    let tab = Object.keys(tabDict).find(key => tabDict[key] === tabIndex)
    navigate(`${url}?tab=${tab}`)
  }


  renderTableTabs() {
    let { tableinfo } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    let myParam = urlParams.get('tab');
    if (myParam) {
      myParam = myParam.toLowerCase()
    }
    let tabDict = {
      "overview": 0,
      "details": 1,
      "schema": 2,
      "preview": 3,
      "pipeline": 4,
      "history": 5,
      "dataexploration": 6
    }
    let currentTab = 0
    if (!tabDict[myParam]) {
      currentTab = 0
    } else {
      currentTab = tabDict[myParam]
    }
    return (
      <Tabs defaultIndex={currentTab} onSelect={index => this.navigateTab(index, tabDict)}>
        <TabList>
          <Tab>Overview</Tab>
          <Tab>Details</Tab>
          <Tab>Schema</Tab>
          <Tab>Preview</Tab>
          <Tab disabled={this.disabledIfNotCommonDataset()}>Pipeline</Tab>
          <Tab disabled={this.disabledIfNotCommonDataset()}>History</Tab>
        </TabList>
        <TabPanel>
          { this.renderTableOverview() }
        </TabPanel>
        <TabPanel>
          {this.disabledIfNotCommonDataset() ? this.renderTableDataExploration() : this.renderTableDetail()}
        </TabPanel>
        <TabPanel>
          {this.disabledIfNotCommonDataset() ? this.renderUserTableSchema() : this.renderTableSchema()}
        </TabPanel>
        <TabPanel>
          {this.disabledIfNotCommonDataset() ? this.renderUserTablePreview() : this.renderTablePreview()}
        </TabPanel>
        <TabPanel>
          <div className="half-vh">
            { this.renderTablePipeline() }
            { this.renderPipelineInfor() }
            <br/>
            <h2>Query</h2>
            { this.renderTableQuery() }
          </div>
        </TabPanel>
        <TabPanel>
          { this.renderTableHistory() }
        </TabPanel>
      </Tabs>
    );
  }


  isEquivalent(a, b) {
      // Create arrays of property names
      if (!a) {
        a = {}
      }
      if (!b) {
        b = {}
      }
      var aProps = Object.getOwnPropertyNames(a);
      var bProps = Object.getOwnPropertyNames(b);

      // If number of properties is different,
      // objects are not equivalent
      if (aProps.length != bProps.length) {
          return false;
      }

      for (var i = 0; i < aProps.length; i++) {
          var propName = aProps[i];

          // If values of same property are not equal,
          // objects are not equivalent
          if (a[propName] !== b[propName]) {
              return false;
          }
      }

      // If we made it this far, objects
      // are considered equivalent
      return true;
  }


  updateDatasetInfo(datasetinfo) {
    if (!this.isEquivalent(datasetinfo, this.state.datasetInfo)) {
      this.setState({ datasetInfo: datasetinfo, addingDataset : null, tableinfo: null})
    }
  }


  updateTableInfo(tableinfo, itemProps) {
    // tableinfo has the following "expected" properties
    //
    // tableinfo.table_id
    // tableinfo.schema
    // tableinfo.dependentPipeline
    // tableinfo.inputTables
    // tableinfo.outputTables
    // tableinfo.query
    // tableinfo.pipeline_id
    if (!this.isEquivalent(tableinfo, this.state.tableinfo)) {
      this.setState({
        tableinfo: tableinfo, tableId: tableinfo.tableId, addingDataset: null, datasetInfo: null
      })
      this.fetchTableData(tableinfo)
    }
  }


  endAddingDataset() {
    this.setState({ addingDataset: null })
  }


  renderLeft() {
    return (
      <div className="rows">
        <div className="row is-full">
          <DatasetList
            onClickAddDataset={this.onClickAddDataset}
            onClickTable={this.updateTableInfo}
            onClickDataset={this.updateDatasetInfo}
            onEndAddingDataset={this.endAddingDataset}
          />
        </div>
      </div>
    );
  }


  renderRight() {
    const { tableinfo, date, addingDataset, addingTable, uniqueDatasetList, datasetInfo } = this.state;

    if (addingDataset != null) {
      return (
        <div>
          <DatasetRegistration date={date} uniqueDatasetList={uniqueDatasetList} onEndAddingDataset={this.endAddingDataset}/>
        </div>
      )
    }

    if (addingDataset == null && addingTable == null && datasetInfo != null) {

      return (
        <div className="has-text-centered">
          <div className="is-vcentered" style={{ margin: 'auto' }}>
            <DatasetInfo data={datasetInfo} date={date} isEditing={false} />
          </div>
        </div>
      );
    }

    if (addingDataset == null && addingTable != null) {
      return (
        <div>
          {this.renderAddTable()}
        </div>
      )
    }

    if (addingDataset == null && addingTable == null && tableinfo == null && datasetInfo == null) {
      return (
        <div className="has-text-centered">
          <div className="is-vcentered" style={{ margin: 'auto' }}>
            <FontAwesomeIcon
              icon={faDiceD6}
              style={{ fontSize: '5em', color: 'lightgrey', margin: '20px' }}
            />
            <p>
              Select a dataset to begin.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="rows">
        <div className="row is-full">
          {this.renderTableHeader()}
        </div>
        <div className="row is-full">
          {this.renderTableTabs()}
        </div>
      </div>

    );
  }


  renderError() {
    const messageClass = 'is-danger';
    const errorReason = 'It seems like something went wrong with the Datalake Browser.';

    const { error } = this.state;

    return (
      <div className="columns is-full">
        <article className={`message ${messageClass}`}>
          <div className="message-body">
            { errorReason }
            <br />
            <i>
              {' '}
              Error:
              { error.message }
              {' '}

            </i>
          </div>
        </article>
      </div>
    );
  }


  render() {
    const { error } = this.state;

    if (error) {
      return (
        <div className="section">
          { this.renderError() }
        </div>
      );
    }

    return (
      <div className="content" style={{ marginBottom: '-60px' }}>
        <div className="columns is-tablet is-multiline datalake-content">
          <div className="column is-full-mobile is-one-third-tablet is-one-quarter-desktop datalake-left" style={{ backgroundColor: 'white', marginTop: '-10px', marginLeft: '0px', paddingLeft: '0px', borderRight: '7px solid #f5f7f7'}}>
            { this.renderLeft() }
          </div>
          <div className="column is-full-mobile is-two-thirds-tablet is-three-quarters-desktop" style={{ backgroundColor: 'white', paddingLeft: '5px' }}>
            { this.renderRight() }
          </div>
        </div>
      </div>
    );
  }
}


export default DatalakeBrowser;
