import React from 'react';
import BaseMetric from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseMetric';

class PortalSummary extends BaseMetric {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            date: null,
            data: {
                successful: 0,
                failed: 0,
            },
        }
    }

    getStatus() {
        if (this.state.isLoading) {
          return "loading";
        }
        if (this.state.data.failed == 0) {
          return "pass"
        } else {
          return "fail"
        }
    }

    getTitle() {
        return "Summary";
    }

    getApiPath() {
        return "status/portal/summary";
    }

    renderMetric() {
        return (
            <div className="level">
                <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">4xx Errors</p>
                      <p className="title">{this.state.data.error4xx}</p>
                    </div>
                </div>
                <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">5xx Errors</p>
                      <p className="title">{this.state.data.error5xx}</p>
                    </div>
                </div>
            </div>
        )
    }
}


export default PortalSummary
