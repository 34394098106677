import React, { Component } from 'react';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import { pipelineJson } from '@tra-sg/gatsby-theme-c360-portal/src/data/config';
import Cookies from 'universal-cookie';
import PipelineNextRun from '@tra-sg/gatsby-theme-c360-portal/src/components/PipelineNextRun';

const cookies = new Cookies();

function callMouseOverFunction(elementID) {
  return () => {
    const x = document.getElementById(elementID);
    const evt = new Event('mouseover');
    x.dispatchEvent(evt);
  };
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function parseDate(dateString) {
  if (dateString) {
    let str_date = `${dateString} UTC`
    return (new Date(Date.parse(str_date))).toLocaleString();
  } else {
    return `Not available`
  }
}

function getStatus(status) {
  let statusDescription = '';
  if (status) {
    if (status === 'SUCCEEDED') {
      statusDescription = '✔';
    } else if (status === 'FAILED') {
      statusDescription = '✖';
    } else {
      statusDescription = 'CANCELLED';
    }
  }
  return statusDescription;
}

function generateDatasetTables(props) {
  const dataset_table_div = [];
  const pipeline_name = props.pipeline_name;
  const input_table = props.input_table;
  const output_table = props.output_table;
  Object.keys(props).forEach((key) => {
    if (key != 'pipeline_name' && props[key] != '') {
      dataset_table_div.push(
        <div key={key} id={key} className="column is-6 dataset-table">
          <h4 className="has-text-centered has-text-weight-semibold">
            {(key) === 'input_table' ? 'Input Tables' : 'Output Tables'}
          </h4>
          <br />
          <table className="table is-bordered is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th>Table</th>
                <th>Rows</th>
                <th>Updated</th>
                <th>Health</th>
              </tr>
            </thead>
            <tbody>
              {props[key].map((table) =>
                {
                  if (!table.dataset_table_id.includes("_waiter") && !table.dataset_table_id.includes("bq_")) {
                    return (
                    <tr key={table.dataset_table_id} onClick={callMouseOverFunction(table.dataset_table_id)} id={`${table.dataset_table_id}_tr`}>
                      <th>{table.dataset_table_id}</th>
                      <td style={{ textAlign: 'right' }}>{numberWithCommas(table.row_count)}</td>
                      <td style={{ textAlign: 'center' }}>{parseDate(table.date)}</td>
                      <td className={table.status}>
                        { getStatus(table.status) }
                      </td>
                    </tr>
                  )
                  }
                })}
            </tbody>
          </table>
        </div>,
      );
    }
  });
  return (
    dataset_table_div
  );
}
export default class Pipelines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      pipeline: null,
      input_table: null,
      output_table: null,
      pipeline_name: null,
      source_tables: [],
      tables: [],
    };
  }

  fetchData() {
    const { date } = this.props;
    const { pipeline_name } = this.props;
    this.setState({
      isLoading: true,
      pipeline_name: pipeline_name
    });

    callApi(
      `${pipelineJson}_${pipeline_name}_${date}.json`,
      (result) => {
        const loaded_pipeline = result.pipeline;
        const source_tables = [];
        const tables = [];
        if (loaded_pipeline == null) throw Error('Invalid pipeline info received.');
        this.props.parentPage.updatePipeline(loaded_pipeline)
        // loop through the loaded_pipeline and get list of source_tables and tables
        loaded_pipeline.forEach(function (pipeline) {
            tables.push(pipeline.dataset_table_id)
            if (pipeline.source) {
              const sources = pipeline.source
              sources.forEach(function (source) {
                source_tables.push(source.table_id)
              })
            };
        });
        this.setState({
            isLoading: false,
            error: null,
            pipeline: loaded_pipeline,
            source_tables: source_tables,
            tables: tables,
          });
      },
      (error) => this.setState({ error, isLoading: false })
    )
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date || this.props.pipeline_name !== prevProps.pipeline_name) {
      this.fetchData();
    }
  }

  renderError() {
    const { date } = this.props;
    const { pipeline_name } = this.props;
    var message_class, error_reason, error_message;
    if (this.state.error.message.includes('404')) {
      message_class = 'is-warning'
      error_reason = `Data for ${pipeline_name} pipeline on ${date} not found. `
      error_message =
        <div>
          {error_reason}
          Next scheduled run: <PipelineNextRun pipeline_name={this.props.pipeline_name}/>
        </div>
    } else {
      message_class = 'is-danger'
      error_reason = 'It seems like something went wrong with the Pipelines.'
      error_message =
        <div>
          {error_reason}
          <br />
          <i>
            {' '}
          Error:
            { this.state.error.message }
            {' '}

          </i>
        </div>
    }

    return (
      <div className="columns is-full">
        <article className={`message ${message_class}`}>
          <div className="message-body">
            { error_message }
          </div>
        </article>
      </div>
    );
  }

  render() {
    const { error, isLoading, pipeline, pipeline_name, source_tables, tables } = this.state;
    if (error) {
      return (
        <div className="section">
          { this.renderError() }
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="columns is-centered">
          <div className="iframe-holder" />
        </div>
      );
    }

    let input_table
    let output_table
    let input_table_id_list = []
    let output_table_id_list = []

    // get the input table lists by getting all table id in source
    // but not in main tables
    source_tables.forEach(function (source_table) {
        if (!tables.includes(source_table)) {
          input_table_id_list.push(source_table)
        }
    });

    // get the output table lists by getting all table id in main
    // but not in source tables
    tables.forEach(function (table) {
        if (!source_tables.includes(table)) {
          output_table_id_list.push(table)
        }
    });
    // TODO: get all possible input tables
    input_table = []
    pipeline.forEach(function (e) {
      if (e.source) {
        let sources = e.source
        sources.forEach(function (source) {
          if (source.table_id) {
            if (input_table_id_list.includes(source.table_id)) {
              input_table.push({
                dataset_table_id: source.table_id > "" ? source.table_id : "",
                date: source.date > "" ? source.date : "",
                row_count: source.row_count > "" ? source.row_count : "",
                status: source.status > "" ? source.status : ""
              })
            }
          }
        }
      )
      }
    })
    output_table = pipeline.filter((e) => output_table_id_list.includes(e.dataset_table_id));
    this.state.input_table = input_table;
    this.state.output_table = output_table;

    return (
      <div className="section">
        <div className="columns is-full is-desktop">
          { generateDatasetTables({ input_table, output_table, pipeline_name }) }
        </div>
      </div>
    );
  }
}
