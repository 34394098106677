module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[""],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/codebuild/output/src579595757/src/ulph-c360-portal/node_modules/@tra-sg/gatsby-theme-c360-portal/src/layouts/index.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":2048}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src579595757/src/ulph-c360-portal/node_modules/@tra-sg/gatsby-theme-c360-portal/src/layouts/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"c360 – Tenant","short_name":"c360","start_url":"/index.html","display":"standalone","icon":"src/assets/img/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a34cc8db19421806ecceb7ca0e4b2643"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@tra-sg/gatsby-theme-c360-portal/gatsby-browser.js'),
      options: {"plugins":[],"sections":[{"name":"tindahan","navText":"Tindahan","templatePath":"src/@tra-sg/gatsby-theme-c360-portal/templates/tindahan-page.js"},{"name":"notification","navText":"Notification","templatePath":"src/@tra-sg/gatsby-theme-c360-portal/templates/notification-page.js"},{"name":"models","navText":"Models Management","templatePath":"src/@tra-sg/gatsby-theme-c360-portal/templates/model-page.js"},{"name":"pipelines","navText":"Pipelines","templatePath":"src/@tra-sg/gatsby-theme-c360-portal/templates/pipelines-page.js"}],"useDefaultSections":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
