import React from 'react';

// Metrics
import PipelinesSummary from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPipelines/Summary';
import ErrorReporting from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPipelines/ErrorReporting';
import ErrorTickets from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPipelines/ErrorTickets';
import FinishedPipelines from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPipelines/FinishedPipelines';
import UpcomingPipelines from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPipelines/UpcomingPipelines';
import PipelineHistory from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPipelines/PipelineHistory';
import PipelinesDiagramCard from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPipelines/PipelinesDiagramCard';
import PipelineBreadCrumb from '@tra-sg/gatsby-theme-c360-portal/src/components/PipelineBreadCrumb';
import HealthcheckDiagram from '@tra-sg/gatsby-theme-c360-portal/src/components/HealthcheckDiagram';
import Pipelines from '@tra-sg/gatsby-theme-c360-portal/src/components/Pipelines';
import DatePicker from 'react-date-picker';
import { navigate } from "@reach/router";
import SystemStatusStickyHeader from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusOverview/StickyHeader';
import moment from 'moment';


class StatusPipelines extends React.Component {

    constructor(props) {
        super(props);

        let url = window.location.href
        let date = ''
        let date_picker = ''
        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }

        let url_suffix = url.substring(url.lastIndexOf('/') + 1);

        if (moment(url_suffix, 'YYYYMMDD', true).isValid()) {
          // check if its in the valid date format
          date = url.substring(url.length - 8, url.length)
        } else {
          // if not a date, default to today
          date = [
                (new Date()).getFullYear(),
                ('0' + ((new Date()).getMonth() + 1)).slice(-2),
                ('0' + (new Date()).getDate()).slice(-2)
              ].join('')
        }

        date_picker = new Date(date.substring(0,4), date.substring(4,6) - 1, date.substring(6,8))

        this.state = {
            health: true,
            lastUpdate: new Date(),
            date_picker: date_picker,
            date: date,
            currentPipelineId: "pipeline_group",
            pipeline: ""
        }

        this.clickRefresh = this.clickRefresh.bind(this);
        this.updateHealth = this.updateHealth.bind(this);
        this.updatePipelineId = this.updatePipelineId.bind(this);
        this.updatePipeline = this.updatePipeline.bind(this);
        this.onChange = this.onChange.bind(this);

        this.childRefs = [];
    }

    // componentDidMount () {
    //   this.onChange()
    // }

    onChange(date_picker) {
      let ddate = [
        date_picker.getFullYear(),
        ('0' + (date_picker.getMonth() + 1)).slice(-2),
        ('0' + date_picker.getDate()).slice(-2)
      ].join('');

      this.setState({
        date_picker: date_picker,
        date: ddate
      })

      let url = window.location.href
      if (url.slice(-1) == "/") {
        url = url.slice(0, -1)
      }
      let array_url = url.split('/')
      let url_suffix = url.substring(url.lastIndexOf('/') + 1);

      if (moment(url_suffix, 'YYYYMMDD', true).isValid()) {
        url = url.substring(0, url.length - 8)
        url = url + `${ddate}`
      } else {
        url = url + `/${ddate}`
      }
      navigate(url)
    }

    clickRefresh() {
        this.childRefs.forEach((c) => c.setState({isLoading: true}));
        this.childRefs.forEach((c) => c.fetchData());
        this.setState({lastUpdate: new Date()});
    }

    updateHealth() {
      if (this.childRefs.some((c) => (c.getStatus() == "pass" && c.getTitle() == "Error Tickets"))) {
        this.setState({health: 'pass'});
      } else if (this.childRefs.some((c) => (c.getStatus() == "fail" && c.getTitle() == "Error Tickets"))) {
        this.setState({health: 'fail'});
      } else if (this.childRefs.some((c) => (c.getStatus() == "loading"))) {
        this.setState({health: 'loading'});
      }
    }

    updatePipelineId(pipelineId) {
        this.setState({
          currentPipelineId: pipelineId
        })
    }

    updatePipeline(pipeline) {
        this.setState({
          pipeline: pipeline
        })
    }

    getPipelineDate() {
        let date = this.state.date
        return date
    }

    getPipelineGroupID() {
        let url = window.location.href
        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }
        let array_url = url.split('/')

        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }

        let url_suffix = url.substring(url.lastIndexOf('/') + 1);

        if (moment(url_suffix, 'YYYYMMDD', true).isValid()) {
          if (array_url[array_url.length - 2] == 'pipelines') {
            return "Pipelines"
          } else {
            url = array_url[array_url.length - 2]
            if (url.includes("_group")) {
              url = url + " Pipeline Group"
            } else {
              url = url + " Pipeline"
            }
            return url
          }
        } else {
          if (array_url[array_url.length - 1] == 'pipelines') {
            return "Pipelines"
          } else {
            url = array_url[array_url.length - 1]
            if (url.includes("_group")) {
              url = url + " Pipeline Group"
            } else {
              url = url + " Pipeline"
            }
            return url
          }
        }
    }

    getPipelineTitle() {
        let url = window.location.href
        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }
        let array_url = url.split('/')

        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }

        let url_suffix = url.substring(url.lastIndexOf('/') + 1);

        if (moment(url_suffix, 'YYYYMMDD', true).isValid()) {
          if (array_url[array_url.length - 2] == 'pipelines') {
            return "Status: Pipelines"
          } else {
            url = array_url[array_url.length - 2]
            if (url.includes("_group")) {
              url = "Status: Pipeline Group"
            } else {
              url = "Status: Pipeline"
            }
            return url
          }
        } else {
          if (array_url[array_url.length - 1] == 'pipelines') {
            return "Status: Pipelines"
          } else {
            url = array_url[array_url.length - 1]
            if (url.includes("_group")) {
              url = "Status: Pipeline Group"
            } else {
              url = "Status: Pipeline"
            }
            return url
          }
        }
    }

    getDetailPipelineGroupID() {
        let url = window.location.href
        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }
        let array_url = url.split('/')

        let url_suffix = url.substring(url.lastIndexOf('/') + 1);

        if (moment(url_suffix, 'YYYYMMDD', true).isValid()) {
          if (array_url[array_url.length - 2] == 'pipelines') {
            return "pipeline_group"
          } else {
            url = array_url[array_url.length - 2]
            return url
          }
        } else {
          if (array_url[array_url.length - 1] == 'pipelines') {
            return "pipeline_group"
          } else {
            url = array_url[array_url.length - 1]
            return url
          }
        }
    }

    renderBody(){
      let url = window.location.href
      if (url.includes('/status/pipeline/')) {
        return (
          <section className="section section--gradient">
            <div className="container home-container">
              <Pipelines date={this.getPipelineDate()} pipeline_name={this.getDetailPipelineGroupID()}
                parentPage={this} />
              <HealthcheckDiagram date={this.getPipelineDate()} pipeline_name={this.getDetailPipelineGroupID()}
                parentPage={this} />
            </div>
          </section>
        )
      } else {
          return (
              <div className='tile is-ancestor is-vertical'>
                  <div className='tile'>
                      <div className='tile is-parent is-4'>
                              <PipelinesSummary parentPage={this} />
                      </div>
                      <div className='tile is-parent is-7'>
                              <ErrorTickets parentPage={this}/>
                      </div>
                  </div>
                  <div className='tile'>
                    <div className='tile is-parent is-11'>
                      <PipelineHistory />
                    </div>
                  </div>
                  <div className='tile is-parent is-11'>
                      <PipelinesDiagramCard parentPage={this}
                      currentPipelineId={this.state.currentPipelineId} date={this.state.date} />
                  </div>
              </div>
          )
      }
    }

    renderContent() {
      let url = window.location.href
      if (url.includes('/status/pipeline/')) {
        return (
          <div className="statuscontent">
            {this.renderBody()}
          </div>
        )
      } else {
          return (
            <div className="statuscontent">
              {this.renderBody()}
            </div>
          )
      }
    }

    render() {
        return(
            <div className="content">
              <SystemStatusStickyHeader
                title={this.getPipelineTitle()}
                onChange={this.onChange}
                selectedDate={this.state.date_picker}
                status={this.state.health}
              >
                <h6>
                  <PipelineBreadCrumb
                    pipeline={this.state.pipeline[0]}
                    date={this.state.date}
                    currentPipelineId={this.getPipelineGroupID()}
                  />
                </h6>
              </SystemStatusStickyHeader>
                { this.renderContent() }
            </div>
        )
    }
}

export default StatusPipelines;
