import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import NavBar from '@tra-sg/gatsby-theme-c360-portal/src/components/NavBar';
import Footer from '@tra-sg/gatsby-theme-c360-portal/src/components/Footer';
import '@tra-sg/gatsby-theme-c360-portal/src/assets/sass/styles.sass';
import config from '@tra-sg/gatsby-theme-c360-portal/src/data/config';
import c360NoText from '@tra-sg/gatsby-theme-c360-portal/src/icons/c360-no-text-00.svg';
import c360TextOnly from '@tra-sg/gatsby-theme-c360-portal/src/icons/c360-text-only-00.svg';
import { loginToApp, getAppUser } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import { Hub } from '@aws-amplify/core'
import PageErrorBoundary from '@tra-sg/gatsby-theme-c360-portal/src/components/PageErrorBoundary';

const isBrowser = typeof window !== 'undefined';

class TemplateWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { isActive: false, isLoggedIn: !config.requiresLogin };
    this.checkIfAuthorized = this.checkIfAuthorized.bind(this);
    global.toggleNavbar = this.toggleNavbar.bind(this);
  }

  componentDidMount() {

    // skip if login not required
    if ( !config.requiresLogin ) return;

    Hub.listen("auth", ({ payload: { event, data } }) => {
        switch (event) {
          case "signIn":
            console.log("Signin success!")
            this.checkIfAuthorized();
            break;
          case "signOut":
            console.log("Signout success!")
            this.setState({ isLoggedIn: false });
            break;
          case "onRouteUpdate":
            console.log("OnRouteUpdate received")
            this.checkIfAuthorized();
            break;
        }
      });

    this.checkIfAuthorized();
  }

  async checkIfAuthorized() {
    if (!config.requiresLogin) {
      console.log("login not required", this.state)
      // skip login checks
      if ( !this.state.isLoggedIn ) this.setState({ isLoggedIn: true });
    }

    try {
      let user = await getAppUser();
      this.setState({ isLoggedIn: true });
    } catch (e) {
      console.log("error on checking", e)
      this.setState({ isLoggedIn: false })
    }
  }

  toggleNavbar() {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  }

  renderAuthorized() {
    const { siteTitle, siteDescription } = config;
    const { children } = this.props;
    const { isActive } = this.state;
    const websiteSchemaOrgJSONLD = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: config.siteUrl.href,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    };
    return (
      <div>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
          {/* Schema.org tags */}
          <script type="application/ld+json">
            {JSON.stringify(websiteSchemaOrgJSONLD)}
          </script>
        </Helmet>
        <NavBar isActive={isActive} toggleNavbar={() => this.toggleNavbar()} authorized={true}/>
        <PageErrorBoundary>
          <div>{children}</div>
        </PageErrorBoundary>
        <Footer />
      </div>
    );
  }

  loginGoogle() {
    if (isBrowser) {
        loginToApp({provider: "Google"});
    }
  }

  renderLoginOption() {
    let {isLoggedIn} = this.state;
    if (isLoggedIn) {
      return (
        <div>
          <div className="column is-3"></div>
          <div className="column is-6">
            <progress className="progress is-small is-primary" max="100"></progress>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{margin: 'auto'}}>
          <a className="button" onClick={() => this.loginGoogle()}>Login with Google</a>
        </div>
      )
    }
  }

  renderLoginError() {
    var loginError = null;
    if (isBrowser) loginError = localStorage.getItem('signinFailureError');  // from gatsby-browser.js
    if (loginError) {
      return (
        <div>
          <div className='message is-danger'>
            <div className="message-body">
              {loginError}
            </div>
          </div>
        </div>
      )
    }
  }

  renderUnauthorized() {
    return (
      <div>
        <Helmet>
          <title>Welcome to c360</title>
          <meta name="description" content='Loading c360..' />
        </Helmet>
        <NavBar isActive={false} authorized={false}/>
        <div>
          <section className="section section--gradient">
            <div className="container" style={{paddingTop:50}}>
              <div className="section content has-text-centered" style={{margin: '9vh'}}>
                <div className="is-hidden-mobile">
                  <img alt="" src={c360NoText} width="30%" height="30%"/>
                  <br/>
                  <img alt="" src={c360TextOnly} width="20%" height="20%" />
                </div>
                <div className="is-hidden-desktop is-hidden-tablet">
                  <img alt="" src={c360NoText} width="50%" height="50%"/>
                  <br/>
                  <img alt="" src={c360TextOnly} width="50%" height="50%" />
                </div>
              </div>
                { this.renderLoginError() }
              <div className="columns section">
                { this.renderLoginOption() }
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }

  render() {
    let { isLoggedIn } = this.state;
    if ( isLoggedIn ) {
      return this.renderAuthorized();
    } else {
      return this.renderUnauthorized();
    }
  }
}

TemplateWrapper.propTypes = {
  children: PropTypes.shape({
    $$typeof: PropTypes.symbol,
  }),
};

TemplateWrapper.defaultProps = {
  children: null,
};

export default TemplateWrapper
