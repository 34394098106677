import React from 'react';


class TableInOutPut extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    let { tableData, isLoading } = this.props

    if (isLoading) {
      return (
        <div className="columns is-centered">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      );
    }

    let inputTables
    let outputTables
    if (tableData) {
      if ((tableData.input_tables || []).length > 0) {
        inputTables = tableData.input_tables.map(e => e.table_id).join(', ')
      } else {
        inputTables = 'None';
      }

      if ((tableData.output_tables || []).length > 0) {
        outputTables = tableData.output_tables.map(e => e.table_id).join(', ')
      } else {
        outputTables = 'None';
      }
    } else {
      inputTables = 'None';
      outputTables = 'None';
    }


    return (
      <div>
        <p className="has-text-weight-light is-size-7">{inputTables}</p>
        <p className="has-text-weight-light is-size-7">{outputTables}</p>
      </div>
    );
  }
}

export default TableInOutPut;
