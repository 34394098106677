import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import BaseMetric from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseMetric';

class InfraOverview extends BaseMetric {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            date: null,
            page: 0,
            data: {
                errors: [],
                summary: {
                    deployed: 1,
                    missing: 0,
                    has_errors: 0,
                }
            },
        }
        this.logPerPage = 3;
    }

    getStatus() {
        if (this.state.isLoading) {
          return "loading";
        } else if (this.state.data.errors.length > 0) {
            return "fail";
        } else if (this.state.data.summary.missing > 0) {
            return "fail";
        }

        return "pass"
    }

    getTitle() {
        return (<Link to="/status/infra">Infrastructure</Link>);
    }

    getApiPath() {
        return "status/infra/overview";
    }

    getItemKey(error_message) {
        return `${error_message.pipeline}-${error_message.message}`;
    }

    renderLogLine(log_item) {
        return (
            <li key={this.getItemKey(log_item)}>
                <p>
                    {
                        (log_item.resource_url > "")
                        ?
                        (<a href={log_item.resource_url}>
                            <strong> {log_item.resource} </strong>
                        </a>)
                        :
                        (<strong> {log_item.resource} </strong>)
                    }
                    - {log_item.message.slice(0, 100)}
                    { log_item.message.length > 100 ? '...' : ''}
                    { log_item.log_url ? (<span> (<a href={log_item.log_url}>logs</a>) </span>) : " " }
                    {
                        log_item.timestamp ?
                        (<span className="has-text-weight-light is-italic is-size-7">
                            {new Date(log_item.timestamp).toString().split('GMT')[0]}
                        </span>)
                        :
                        ""
                    }
                </p>
            </li>
        )
    }

    renderLogs(visibleLogs) {
        if (visibleLogs.length == 0) {
            return (
                <div>
                    <p className="header">No errors.</p>
                </div>
            )
        } else {
            return (
                <div>
                    <ul>
                        { visibleLogs.map((e) => (this.renderLogLine(e))) }
                    </ul>
                </div>
            )
        }
    }

    renderPagination(validLogs) {
        if (validLogs.length <= this.logPerPage ) {
            return (<div></div>)
        }
        let maxPage = Math.ceil(validLogs.length / this.logPerPage);

        return (
            <div style={{marginTop: '12px'}}>
                <nav className="pagination is-small is-centered">
                    <a
                        className="pagination-previous"
                        onClick={() => {
                            if (this.state.page > 0) {
                                this.setState({page: this.state.page - 1})}
                            }
                        }
                    >Previous</a>
                    <a
                        className="pagination-next"
                        onClick={() => {
                            if (this.state.page < maxPage -1) {
                                this.setState({page: this.state.page + 1})}
                            }
                        }
                    >Next</a>
                    <ul className="pagination-list">
                        {
                            [...Array(maxPage).keys()].map(
                                (k) => (
                                    <li className={
                                            (k == this.state.page) ?
                                            "pagination-link is-current"
                                            : "pagination-link"
                                        }
                                            onClick={() => this.setState({page: (k)})}
                                        >{(k + 1).toString()}
                                    </li>
                                )
                            )
                        }
                    </ul>
                </nav>
            </div>
        )
    }

    renderSummary() {
        return (
            <div className="level">
                <div className="level-item has-text-centered">
                    <div>
                    <p className="heading">Deployed</p>
                    <p className="title">{this.state.data.summary.deployed}</p>
                    </div>
                </div>
                <div className="level-item has-text-centered">
                    <div>
                    <p className="heading">Missing</p>
                    <p className="title">{this.state.data.summary.missing}</p>
                    </div>
                </div>
                <div className="level-item has-text-centered">
                    <div>
                    <p className="heading">Has Errors</p>
                    <p className="title">{this.state.data.summary.has_errors}</p>
                    </div>
                </div>
            </div>
        )
    }

    renderMetric() {
        let visibleLogs = this.state.data.errors.slice(
            (this.state.page) * this.logPerPage, (this.state.page + 1) * this.logPerPage
        )
        return (
            <div className="tiles">
                <p className="heading">Summary</p>
                { this.renderSummary() }
                <div>
                    <p className="heading">Errors</p>
                    { this.renderLogs(visibleLogs) }
                    { this.renderPagination(this.state.data.errors) }
                </div>
            </div>
        )
    }
}


export default InfraOverview
