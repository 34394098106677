import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import React from 'react';


class NewNotebook extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      error: null,
      isLoading: false
    };
  }

  fetchData() {
    const callApiUrl = `lake/browser/create_notebook`
    this.setState({ isLoading: true });

    callApi(
      callApiUrl,
      (result) => {
        this.setState({
          isLoading: true,
        });
        const loadedResult = result.data;
        if (loadedResult == null) throw Error('Fail to create new notebook.');
        this.setState({
          error: null,
          data: loadedResult,
          isLoading: false,
        });
        window.open(this.state.data, "_blank")
      },
      (error) => this.setState({ error, isLoading: false }),
    );
  }


  render() {
    const { error, isLoading, data } = this.state;

    if (error) {
      return (
        <button className="button" title="Disabled button" disabled>Not Available</button>
      )
    }

    if (isLoading) {
      return (
        <button className="button is-loading">Loading</button>
      );
    }

    return (
      <div>
        <button
          className="button"
          title="Explore Notebook"
          onClick={() => {
            this.fetchData()
          }}
          >
            Explore
          </button>
          <p className="is-italic is-size-7"> in a new Notebook</p>
      </div>
    );
  }
}

export default NewNotebook;
