import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { pipelineJson } from '@tra-sg/gatsby-theme-c360-portal/src/data/config';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


export default class TableDataExploration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      data: null
    };
  }


  fetchData() {
    const { table_id, zone_id, dataset_id, group_id } = this.props;
    let callApiUrl = `dataset/table/initial_data_exploring?dataset=${dataset_id}&table=${table_id}&zone=${zone_id}&groups=${group_id}`

    this.setState({ isLoading: true });

    callApi(
      callApiUrl,
      (result) => {
        let loaded_result = result.data;

        if (loaded_result == null) throw Error('Invalid data exploration received.');
        this.setState({
          isLoading: false,
          error: null,
          data: loaded_result
        });
      },
      (error) => this.setState({ error, isLoading: false })
    )
  }


  componentDidMount() {
    this.fetchData();
  }


  componentDidUpdate(prevProps) {
    if (this.props.dataset_id !== prevProps.dataset_id || this.props.zone_id !== prevProps.zone_id || this.props.table_id !== prevProps.table_id) {
      this.fetchData();
    }
  }


  render() {
    const { error, isLoading, data } = this.state;
    if (error) {
      return (
        <div>
          Not Available at the moment. Please come back later.
        </div>
      );
    }

    if (isLoading) {
      return (
        <div>
          <div className="iframe-holder" />
        </div>
      );
    }

    return (
      <div>
        <iframe srcdoc={data} width="100%" height="600px"></iframe>
      </div>
    );
  }
}
