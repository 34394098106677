import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { pipelineJson } from '@tra-sg/gatsby-theme-c360-portal/src/data/config';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


export default class UserTablePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      data: null
    };
  }


  fetchData() {
    const { table_id, zone_id, dataset_id, group_id } = this.props;
    let callApiUrl = `dataset/table/get_data_exploration_json?dataset=${dataset_id}&table=${table_id}&zone=${zone_id}&groups=${group_id}`

    this.setState({ isLoading: true });

    callApi(
      callApiUrl,
      (result) => {
        let loaded_result = result.data;

        if (loaded_result == null) throw Error('Invalid Table Preview received.');
        this.setState({
          isLoading: false,
          error: null,
          data: loaded_result
        });
      },
      (error) => this.setState({ error, isLoading: false })
    )
  }


  componentDidMount() {
    this.fetchData();
  }


  componentDidUpdate(prevProps) {
    if (this.props.dataset_id !== prevProps.dataset_id || this.props.zone_id !== prevProps.zone_id || this.props.table_id !== prevProps.table_id) {
      this.fetchData();
    }
  }


  render() {
    const { error, isLoading, data } = this.state;

    if (error) {
      return (
        <div>
          Table Preview is not available at the moment. Please comeback later.
        </div>
      );
    }

    if (isLoading) {
      return (
        <div>
          <div className="iframe-holder" />
        </div>
      );
    }

    let tableBodyDiv = []
    let headerTitleDiv = []
    let tableBodyRow = []

    if (data) {
      if (data.sample) {
        let datasample = data['sample'][0]['data']
        let cols = Object.keys(datasample[0])
        cols.map(key => {
          headerTitleDiv.push(
            <th>{key}</th>
          )
        })
        datasample.map(row => {
          tableBodyRow = []
          cols.map(key => {
            tableBodyRow.push(
              <td>{row[key]}</td>
            )
          })
          tableBodyDiv.push(
            <tr>
              { tableBodyRow }
            </tr>
        )
      })
    }
  }

    return (
      <table className="table is-bordered is-fullwidth is-size-7">
        <thead>
          <tr>
            { headerTitleDiv }
          </tr>
        </thead>
        <tbody>
            { tableBodyDiv }
        </tbody>
      </table>
    );
  }
}
