import React from 'react';

// Metrics
import LoginSummary from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPortal/LoginSummary';
import PortalSummary from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPortal/Summary';
import ErrorReporting from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPortal/ErrorReporting';
import SupersetDashboard from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPortal/SupersetDashboard';
import PageRendering from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPortal/PageRendering';
import QuicklookApi from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPortal/QuicklookApi';
import DatePicker from 'react-date-picker';
import { Link, navigate } from "@reach/router";
import SystemStatusStickyHeader from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusOverview/StickyHeader';


class StatusPortal extends React.Component {

    constructor(props) {
        super(props);

        let url = window.location.href
        let date = ''
        let date_picker = ''
        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }
        if (url.substring(url.lastIndexOf('/') + 1).match(/20*/)) {
          date = url.substring(url.length - 8, url.length)
        } else {
          date = [
                (new Date()).getFullYear(),
                ('0' + ((new Date()).getMonth() + 1)).slice(-2),
                ('0' + (new Date()).getDate()).slice(-2)
              ].join('')
        }

        date_picker = new Date(date.substring(0,4), date.substring(4,6) - 1, date.substring(6,8))

        this.state = {
            health: true,
            lastUpdate: new Date(),
            date_picker: date_picker,
            date: date,
            currentPipelineId: "pipeline_group",
            pipeline: ""
        }

        this.clickRefresh = this.clickRefresh.bind(this);
        this.updateHealth = this.updateHealth.bind(this);
        this.updatePipelineId = this.updatePipelineId.bind(this);
        this.updatePipeline = this.updatePipeline.bind(this);
        this.onChange = this.onChange.bind(this);

        this.childRefs = [];
    }

    onChange(date_picker) {
      let ddate = [
        date_picker.getFullYear(),
        ('0' + (date_picker.getMonth() + 1)).slice(-2),
        ('0' + date_picker.getDate()).slice(-2)
      ].join('');

      this.setState({
        date_picker: date_picker,
        date: ddate
      })

      let url = window.location.href
      if (url.slice(-1) == "/") {
        url = url.slice(0, -1)
      }
      let array_url = url.split('/')
      if (url.substring(url.lastIndexOf('/') + 1).match(/20*/)) {
        url = url.substring(0, url.length - 8)
        url = url + `${ddate}`
      } else {
        url = url + `/${ddate}`
      }
      navigate(url)
    }

    clickRefresh() {
        this.childRefs.forEach((c) => c.setState({isLoading: true}));
        this.childRefs.forEach((c) => c.fetchData());
        this.setState({lastUpdate: new Date()});
    }

    updateHealth() {
      if (this.childRefs.every((c) => (c.getStatus() == "pass"))) {
        this.setState({health: 'pass'});
      } else if (this.childRefs.some((c) => (c.getStatus() == "fail"))) {
        this.setState({health: 'fail'});
      } else if (this.childRefs.some((c) => (c.getStatus() == "loading"))) {
        this.setState({health: 'loading'});
      }
    }

    updatePipelineId(pipelineId) {
        this.setState({
          currentPipelineId: pipelineId
        })
    }

    updatePipeline(pipeline) {
        this.setState({
          pipeline: pipeline
        })
    }

    getPipelineGroupID() {
        let url = window.location.href
        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }
        let array_url = url.split('/')

        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }

        if (url.substring(url.lastIndexOf('/') + 1).match(/20*/)) {
          if (array_url[array_url.length - 2] == 'pipelines') {
            return "Pipelines"
          } else {
            url = array_url[array_url.length - 2]
            if (url.includes("_group")) {
              url = url + " Pipeline Group"
            } else {
              url = url + " Pipeline"
            }
            return url
          }
        } else {
          if (array_url[array_url.length - 1] == 'pipelines') {
            return "Pipelines"
          } else {
            url = array_url[array_url.length - 1]
            if (url.includes("_group")) {
              url = url + " Pipeline Group"
            } else {
              url = url + " Pipeline"
            }
            return url
          }
        }
    }

    renderHeader() {
        let successHeader = (
            <article className="message is-success">
                <div className="message-body level">
                    <p className="level-left"> System health is good. </p>
                    <div className="level-right">
                        <div className="has-text-weight-light is-italic is-size-7">
                            Last refreshed on {this.state.lastUpdate.toString().split('GMT')[0]}&nbsp;
                        </div>
                        <button className="button" onClick={this.fetchData}>Refresh Data</button>
                    </div>
                </div>
            </article>
        )

        let errorHeader = (
            <article className="message is-danger">
                <div className="message-body level">
                    <div className="level-left"> System health is compromised. Please check the system report below.</div>
                    <div className="level-right">
                        <div className="has-text-weight-light is-italic is-size-7">
                            Last refreshed on {this.state.lastUpdate.toString().split('GMT')[0]}&nbsp;
                        </div>
                        <button className="button" onClick={this.clickRefresh}>Refresh Data</button>
                    </div>
                </div>
            </article>
        )

        let loadingHeader = (
            <article className="message is-warning">
                <div className="message-body level">
                    <p className="level-left"> System health status is loading, please wait... </p>
                    <div className="level-right">
                        <div className="has-text-weight-light is-italic is-size-7">
                            Last refreshed on {this.state.lastUpdate.toString().split('GMT')[0]}&nbsp;
                        </div>
                        <button className="button" onClick={this.fetchData}>Refresh Data</button>
                    </div>
                </div>
            </article>
        )

        if (this.state.health == "pass") {
          return successHeader
        } else if (this.state.health == "fail") {
          return errorHeader
        } else {
          return loadingHeader
        }
    }

    renderBody(){
        return(
            <div className='tile is-ancestor is-vertical'>
                <div className='tile'>
                    <div className='tile is-parent is-4'>
                            <PortalSummary parentPage={this} />
                    </div>
                    <div className='tile is-parent is-7'>
                        <LoginSummary parentPage={this} />
                    </div>
                </div>
                <div className='tile'>
                    <div className='tile'>
                      <div className='tile is-parent is-11'>
                              <ErrorReporting parentPage={this} />
                      </div>
                    </div>
                </div>
                {/*<div className='tile'>
                    <div className='tile'>
                        <div className='tile is-parent is-12'>
                            <PageRendering parentPage={this} />
                        </div>
                    </div>
                </div>
                <div className='tile'>
                    <div className='tile'>
                        <div className='tile is-parent is-12'>
                            <QuicklookApi parentPage={this} />
                        </div>
                    </div>
                </div>*/}
            </div>
        )
    }

    render() {
        return(
            <div className="content">
            <SystemStatusStickyHeader
              title="Status: Portal"
              onChange={this.onChange}
              selectedDate={this.state.date_picker}
              status={this.state.health}
            >
              <h6> <Link to="/status/overview"> Overview / </Link> Portal </h6>
            </SystemStatusStickyHeader>
              <div className="statuscontent">
                {this.renderBody()}
              </div>
            </div>
        )
    }
}

export default StatusPortal;
