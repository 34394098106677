import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from "@reach/router"
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


export default class PipelineBreadCrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: false,
      pipeline: this.props.pipeline,
      date: this.props.date,
      currentPipelineId: this.props.currentPipelineId
    };
  }

  fetchData() {
    let { currentPipelineId, date } = this.props;
    currentPipelineId = currentPipelineId.split(" ")[0]
    this.setState({
      isLoading: true
    });

    callApi(
      `status/pipelines/pipeline_info/${currentPipelineId}/${date}`,
      (result) => {
        const loaded_pipeline = result.pipeline[0];
        if (loaded_pipeline == null) throw Error('Invalid pipeline info received.');
        this.setState({
            isLoading: false,
            error: null,
            pipeline: loaded_pipeline
          });
      },
      (error) => this.setState({ error, isLoading: false })
    )
  }

  componentDidMount() {
    const { date, pipeline } = this.props;
    if (!pipeline) {
      this.fetchData();
    }
  }

  render() {
    let { pipeline, date, currentPipelineId, isLoading, error } = this.state
    let upper_level = ''

    if (isLoading) {
      return "Loading..."
    }

    if (!isLoading) {
      let url = window.location.href
      if (url.slice(-1) == "/") {
        url = url.slice(0, -1)
      }
      let array_url = url.split('/')

      if (url.slice(-1) == "/") {
        url = url.slice(0, -1)
      }

      let upper_level_url = '/status/pipelines/'
      if (pipeline) {
        upper_level = pipeline.upper_level
      }
      if (upper_level != '') {
        upper_level_url = upper_level_url + upper_level + `/${date}`
      }

      if (url.substring(url.lastIndexOf('/') + 1).match(/20*/)) {
        if (array_url[array_url.length - 2] == 'pipelines') {
          return (<h6> <Link to="/status/overview"> System Status / </Link> Pipelines </h6>)
        } else {
          url = array_url[array_url.length - 2]
          if (url.includes("_group")) {
            return (
              <h6>
                <Link to="/status/overview"> System Status / </Link> <Link to={`status/pipelines/${date}`}>Pipelines</Link> / {currentPipelineId}
              </h6>
            )
          } else {
            return (
              <h6><Link to="/status/overview"> System Status / </Link> <Link to={`status/pipelines/${date}`}>Pipelines</Link> / <Link to={upper_level_url} >{upper_level}</Link> / {currentPipelineId}</h6>
            )
        }
      }
      } else {
        if (array_url[array_url.length - 1] == 'pipelines') {
          return (<h6> <Link to="/status/overview"> System Status / </Link> </h6>)
        } else {
          if (upper_level != '') {
            return (
              <h6>
                <Link to="/status/overview"> System Status / </Link> <Link to={`status/pipelines/${date}`}>Pipelines</Link> / {currentPipelineId}
              </h6>
            )
          } else {
            return (
              <h6><Link to="/status/overview"> System Status / </Link> <Link to={`status/pipelines/${date}`}>Pipelines</Link> / <Link to={upper_level_url} >{upper_level}</Link> / {currentPipelineId}</h6>
            )
          }
        }
      }
    }
  }
}
