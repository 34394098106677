import React from 'react';

import InfraSummary from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusInfra/Summary';
import BackupSummary from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusInfra/BackupSummary';
import InfraErrorReporting from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusInfra/ErrorReporting';
import InfraServices from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusInfra/Services';
import PostgresMetric from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusInfra/PostgresMetric';
import SupersetInstance from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusInfra/SupersetInstance';
import Backup from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusInfra/Backup';
import DatePicker from 'react-date-picker';
import { Link, navigate } from "@reach/router";
import SystemStatusStickyHeader from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusOverview/StickyHeader';
import moment from 'moment';


class StatusInfra extends React.Component {

    constructor(props) {
        super(props);

        let url = window.location.href
        let date = ''
        let date_picker = ''
        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }
        let url_suffix = url.substring(url.lastIndexOf('/') + 1);

        if (moment(url_suffix, 'YYYYMMDD', true).isValid()) {
          date = url.substring(url.length - 8, url.length)
        } else {
          date = [
                (new Date()).getFullYear(),
                ('0' + ((new Date()).getMonth() + 1)).slice(-2),
                ('0' + (new Date()).getDate()).slice(-2)
              ].join('')
        }

        date_picker = new Date(date.substring(0,4), date.substring(4,6) - 1, date.substring(6,8))

        this.state = {
            health: "loading",
            lastUpdate: new Date(),
            date_picker: date_picker,
            date: date,
            currentPipelineId: "pipeline_group",
            pipeline: ""
        }

        this.clickRefresh = this.clickRefresh.bind(this);
        this.updateHealth = this.updateHealth.bind(this);
        this.updatePipelineId = this.updatePipelineId.bind(this);
        this.updatePipeline = this.updatePipeline.bind(this);
        this.onChange = this.onChange.bind(this);

        this.childRefs = [];
    }

    onChange(date_picker) {
      let ddate = [
        date_picker.getFullYear(),
        ('0' + (date_picker.getMonth() + 1)).slice(-2),
        ('0' + date_picker.getDate()).slice(-2)
      ].join('');

      this.setState({
        date_picker: date_picker,
        date: ddate
      })

      let url = window.location.href
      if (url.slice(-1) == "/") {
        url = url.slice(0, -1)
      }
      let array_url = url.split('/')
      let url_suffix = url.substring(url.lastIndexOf('/') + 1);

      if (moment(url_suffix, 'YYYYMMDD', true).isValid()) {
        url = url.substring(0, url.length - 8)
        url = url + `${ddate}`
      } else {
        url = url + `/${ddate}`
      }
      navigate(url)
    }

    clickRefresh() {
        this.childRefs.forEach((c) => c.setState({isLoading: true}));
        this.childRefs.forEach((c) => c.fetchData());
        this.setState({lastUpdate: new Date()});
    }

    updateHealth() {
      if (this.childRefs.every((c) => (c.getStatus() == "pass"))) {
        this.setState({health: 'pass'});
      } else if (this.childRefs.some((c) => (c.getStatus() == "fail"))) {
        this.setState({health: 'fail'});
      } else if (this.childRefs.some((c) => (c.getStatus() == "loading"))) {
        this.setState({health: 'loading'});
      }
    }

    updatePipelineId(pipelineId) {
        this.setState({
          currentPipelineId: pipelineId
        })
    }

    updatePipeline(pipeline) {
        this.setState({
          pipeline: pipeline
        })
    }

    getPipelineGroupID() {
        let url = window.location.href
        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }
        let array_url = url.split('/')

        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }

        let url_suffix = url.substring(url.lastIndexOf('/') + 1);
        if (moment(url_suffix, 'YYYYMMDD', true).isValid()) {
          if (array_url[array_url.length - 2] == 'pipelines') {
            return "Pipelines"
          } else {
            url = array_url[array_url.length - 2]
            if (url.includes("_group")) {
              url = url + " Pipeline Group"
            } else {
              url = url + " Pipeline"
            }
            return url
          }
        } else {
          if (array_url[array_url.length - 1] == 'pipelines') {
            return "Pipelines"
          } else {
            url = array_url[array_url.length - 1]
            if (url.includes("_group")) {
              url = url + " Pipeline Group"
            } else {
              url = url + " Pipeline"
            }
            return url
          }
        }
    }

    renderBody(){
        return(
            <div className='tile is-ancestor is-vertical'>
                <div className='tile'>
                    <div className='tile is-parent is-4'>
                            <InfraSummary parentPage={this} />
                    </div>
                    <div className='tile is-parent is-7'>
                            <InfraErrorReporting parentPage={this} />
                    </div>
                </div>
                <div className='tile is-parent is-11'>
                    <InfraServices parentPage={this} />
                </div>
                {/* <div className='tile'>
                    <div className='tile'>
                        <div className="tile is-parent">
                          <PostgresMetric parentPage={this} />
                        </div>
                        <div className="tile is-parent">
                          <SupersetInstance parentPage={this} />
                        </div>
                      </div>
                </div>
                <div className='tile'>
                    <div className='tile is-parent is-4'>
                            <BackupSummary parentPage={this} />
                    </div>
                    <div className='tile is-parent is-8'>
                            <Backup parentPage={this} />
                    </div>
                </div> */}
            </div>
        )
    }

    render() {
        return(
            <div className="content">
              <SystemStatusStickyHeader
                title="Status: Infrastructure"
                onChange={this.onChange}
                selectedDate={this.state.date_picker}
                status={this.state.health}
              >
                <h6> <Link to="/status/overview"> Overview / </Link> Infra </h6>
              </SystemStatusStickyHeader>
              <div className="statuscontent">
                {this.renderBody()}
              </div>
            </div>
        )
    }
}

export default StatusInfra;
