// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-pages-404-js": () => import("./../../../node_modules/@tra-sg/gatsby-theme-c360-portal/src/pages/404.js" /* webpackChunkName: "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-pages-404-js" */),
  "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-pages-logout-index-js": () => import("./../../../node_modules/@tra-sg/gatsby-theme-c360-portal/src/pages/logout/index.js" /* webpackChunkName: "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-pages-logout-index-js" */),
  "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-pages-tags-index-js": () => import("./../../../node_modules/@tra-sg/gatsby-theme-c360-portal/src/pages/tags/index.js" /* webpackChunkName: "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-pages-tags-index-js" */),
  "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-templates-campaign-page-js": () => import("./../../../node_modules/@tra-sg/gatsby-theme-c360-portal/src/templates/campaign-page.js" /* webpackChunkName: "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-templates-campaign-page-js" */),
  "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-templates-customer-page-js": () => import("./../../../node_modules/@tra-sg/gatsby-theme-c360-portal/src/templates/customer-page.js" /* webpackChunkName: "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-templates-customer-page-js" */),
  "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-templates-dashboard-page-js": () => import("./../../../node_modules/@tra-sg/gatsby-theme-c360-portal/src/templates/dashboard-page.js" /* webpackChunkName: "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-templates-dashboard-page-js" */),
  "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-templates-home-page-js": () => import("./../../../node_modules/@tra-sg/gatsby-theme-c360-portal/src/templates/home-page.js" /* webpackChunkName: "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-templates-home-page-js" */),
  "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-templates-tags-js": () => import("./../../../node_modules/@tra-sg/gatsby-theme-c360-portal/src/templates/tags.js" /* webpackChunkName: "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-templates-tags-js" */),
  "component---src-tra-sg-gatsby-theme-c-360-portal-templates-datalake-page-js": () => import("./../../../src/@tra-sg/gatsby-theme-c360-portal/templates/datalake-page.js" /* webpackChunkName: "component---src-tra-sg-gatsby-theme-c-360-portal-templates-datalake-page-js" */),
  "component---src-tra-sg-gatsby-theme-c-360-portal-templates-labs-page-js": () => import("./../../../src/@tra-sg/gatsby-theme-c360-portal/templates/labs-page.js" /* webpackChunkName: "component---src-tra-sg-gatsby-theme-c-360-portal-templates-labs-page-js" */),
  "component---src-tra-sg-gatsby-theme-c-360-portal-templates-model-page-js": () => import("./../../../src/@tra-sg/gatsby-theme-c360-portal/templates/model-page.js" /* webpackChunkName: "component---src-tra-sg-gatsby-theme-c-360-portal-templates-model-page-js" */),
  "component---src-tra-sg-gatsby-theme-c-360-portal-templates-notification-page-js": () => import("./../../../src/@tra-sg/gatsby-theme-c360-portal/templates/notification-page.js" /* webpackChunkName: "component---src-tra-sg-gatsby-theme-c-360-portal-templates-notification-page-js" */),
  "component---src-tra-sg-gatsby-theme-c-360-portal-templates-pipelines-page-js": () => import("./../../../src/@tra-sg/gatsby-theme-c360-portal/templates/pipelines-page.js" /* webpackChunkName: "component---src-tra-sg-gatsby-theme-c-360-portal-templates-pipelines-page-js" */),
  "component---src-tra-sg-gatsby-theme-c-360-portal-templates-status-page-js": () => import("./../../../src/@tra-sg/gatsby-theme-c360-portal/templates/status-page.js" /* webpackChunkName: "component---src-tra-sg-gatsby-theme-c-360-portal-templates-status-page-js" */),
  "component---src-tra-sg-gatsby-theme-c-360-portal-templates-tindahan-page-js": () => import("./../../../src/@tra-sg/gatsby-theme-c360-portal/templates/tindahan-page.js" /* webpackChunkName: "component---src-tra-sg-gatsby-theme-c-360-portal-templates-tindahan-page-js" */)
}

