import PipelineStatus from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/PipelineStatus';
import Collapsible from 'react-collapsible';
import DatePicker from 'react-date-picker';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import React from 'react';


class TablePipelineInfor extends React.Component {
  constructor(props) {
    super(props);
  }


  renderDependentPipelines() {
    let { tableData, date, isLoading } = this.props
    if (tableData) {
      if ((tableData.dependent_pipelines || []).length > 0) {
        return (
          tableData.dependent_pipelines.map((dependent) => (
            <Collapsible trigger={dependent.pipeline_id} key={dependent.pipeline_id}>
              <div className="column">
                <p className="has-text-weight-light is-size-7">
                  Date:
                  {date}
                </p>
                <p className="has-text-weight-light is-size-7">Status: {<PipelineStatus date={date} pipeline_name={dependent.pipeline_id} />}</p>
                <a className="button" href={`/status/pipeline/${dependent.pipeline_id}/${date}`} id="logout-button"> Detail </a>
              </div>
            </Collapsible>
          ))
        );
      }
    }
    return 'Not Available';
  }


  renderDependencyPipelines() {
    let { tableData, date, isLoading } = this.props
    if (tableData) {
      if ((tableData.dependency_pipelines || []).length > 0) {
        return (
          tableData.dependency_pipelines.map((dependency) => (
            <Collapsible trigger={dependency.pipeline_id} key={dependency.pipeline_id}>
              <div className="column">
                <p className="has-text-weight-light is-size-7">
                  Date:
                  {date}
                </p>
                <p className="has-text-weight-light is-size-7">Status: {<PipelineStatus date={date} pipeline_name={dependency.pipeline_id} />}</p>
                <a className="button" href={`/status/pipeline/${dependency.pipeline_id}/${date}`} id="logout-button"> Detail </a>
              </div>
            </Collapsible>
          ))
        );
      }
    }
    return 'Not Available';
  }


  render() {
    let { tableData, date, isLoading } = this.props

    if (isLoading) {
      return (
        <div className="columns half-vh">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      );
    }

    if (tableData) {
      if (tableData.pipeline_id) {
        return (
          <div className="rows is-full" style={{ backgroundColor: 'white', marginLeft: '15px', paddingTop: '15px' }}>
            <div className="row is-full">
              <h5 className="title">Related Pipelines</h5>
              <Tabs>
                <TabList>
                  <Tab>Dependents</Tab>
                  <Tab>Dependencies</Tab>
                </TabList>

                <TabPanel>
                  <div className="columns">
                    <div className="column">
                      {
                          this.renderDependentPipelines()
                        }
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="columns">
                    <div className="column">
                      {
                          this.renderDependencyPipelines()
                        }
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        );
      } else {
       return (
         <div className="columns">
           <div className="column is-narrow">
             Pipeline info is not available
           </div>
         </div>
       );
     }
   } else {
     return (
       <div className="columns">
         <div className="column is-narrow">
           Pipeline info is not available
         </div>
       </div>
     );
   }
  }
}

export default TablePipelineInfor;
