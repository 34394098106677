import React from 'react';
import DatePicker from 'react-date-picker';
import { Link, navigate } from "@reach/router";
// import StatusBox from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusBox';
import SystemStatusStickyHeader from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusOverview/StickyHeader'
import PipelineOverview from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPipelines/Overview';
import PortalOverview from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPortal/Overview';
import InfraOverview from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusInfra/Overview';
import OverviewSummary from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPortal/OverviewSummary';

class StatusOverview extends React.Component {

    constructor(props) {
        super(props);

        let url = window.location.href
        let date = ''
        let date_picker = ''
        if (url.slice(-1) == "/") {
          url = url.slice(0, -1)
        }
        if (url.substring(url.lastIndexOf('/') + 1).match(/20*/)) {
          date = url.substring(url.length - 8, url.length)
        } else {
          date = [
                (new Date()).getFullYear(),
                ('0' + ((new Date()).getMonth() + 1)).slice(-2),
                ('0' + (new Date()).getDate()).slice(-2)
              ].join('')
        }

        date_picker = new Date(date.substring(0,4), date.substring(4,6) - 1, date.substring(6,8))

        this.state = {
            health: 'pass',
            lastUpdate: new Date(),
            date_picker: date_picker,
            date: date
        }

        this.clickRefresh = this.clickRefresh.bind(this);
        this.updateHealth = this.updateHealth.bind(this);
        this.onChange = this.onChange.bind(this);

        this.childRefs = [];
    }

    onChange(date_picker) {
      let ddate = [
        date_picker.getFullYear(),
        ('0' + (date_picker.getMonth() + 1)).slice(-2),
        ('0' + date_picker.getDate()).slice(-2)
      ].join('');

      this.setState({
        date_picker: date_picker,
        date: ddate
      })

      let url = window.location.href
      if (url.slice(-1) == "/") {
        url = url.slice(0, -1)
      }
      let array_url = url.split('/')
      if (url.substring(url.lastIndexOf('/') + 1).match(/20*/)) {
        url = url.substring(0, url.length - 8)
        url = url + `${ddate}`
      } else {
        url = url + `/${ddate}`
      }
      navigate(url)
    }

    clickRefresh() {
        this.childRefs.forEach((c) => c.setState({isLoading: true}));
        this.childRefs.forEach((c) => c.fetchData());
        this.setState({lastUpdate: new Date()});
    }

    updateHealth() {
      if (this.childRefs.every((c) => (c.getStatus() == "pass"))) {
        this.setState({health: 'pass'});
      } else if (this.childRefs.some((c) => (c.getStatus() == "fail"))) {
        this.setState({health: 'fail'});
      } else if (this.childRefs.some((c) => (c.getStatus() == "loading"))) {
        this.setState({health: 'loading'});
      }
    }

    renderBody(){
        return(
            <div className='tile is-ancestor is-vertical'>
                <div className='tile'>
                    <div className='tile is-parent is-6'>
                      <PipelineOverview title='Pipelines' parentPage={this}/>
                    </div>
                    <div className='tile is-parent is-5'>
                      <InfraOverview parentPage={this}/>
                    </div>
                </div>
                <div className='tile'>
                    <div className='tile is-parent is-11'>
                      <PortalOverview title='Portal' parentPage={this}/>
                    </div>
                    {/* <div className='tile is-parent is-6'>
                      <StatusBox
                          title="API"
                          successMessage="11111"
                          errorMessage="1111"
                          status="loading"
                      >
                      </StatusBox>
                    </div> */}
                </div>
            </div>
        )
    }

    render() {
        return(
            <div className="content">
              <SystemStatusStickyHeader
                onChange={this.onChange}
                selectedDate={this.state.date_picker}
                status={this.state.health}
              />
              <div className="statuscontent">
                {this.renderBody()}
              </div>
            </div>
        )
    }
}

export default StatusOverview;
