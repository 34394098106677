import React from 'react';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


let STATUS_READY = "Ready";
let STATUS_IN_PROGRESS = "InProgress";
let STATUS_FAIL = "Failure";


class PipelineCard extends React.Component {
  constructor(props) {
    /*
      Component for a single Pipeline card. Expects the following prop
      * pipeline_name
    */
    super(props);

    this.state = {
      name: this.props.pipeline_name,
      status: null,
      error: null,
      isLoading: true,
    }

    this.onClickRun = this.onClickRun.bind(this)
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    let { name } = this.state;
    const callApiUrl = `pipeline/${name}`;
    this.setState({ isLoading: true });
    callApi(
      callApiUrl,
      (result) => {
        const loadedResult = result.data || [];
        this.setState({
          error: null,
          isLoading: false,
          status: loadedResult.status,
        })
      },
      (errors) => {
        this.setState({ errors, isLoading: false })
      },
    );
  }

  async onClickRun() {
    let { name } = this.state;
    const callApiUrl = `pipeline/${name}/run`;
    this.setState({ isLoading: true, status: null });
    callApi(
      callApiUrl,
      (result) => {
        return
        // call to run is async, don't do anything here
      },
      (errors) => {
        this.setState({ errors, isLoading: false })
      },
    );

    // wait 2 second
    await new Promise(r => setTimeout(r, 2000));
    // after starting the async call, try to fetch status
    this.fetchData();
  }

  renderPipelineRight() {
    let { status } = this.state;

    if (status == STATUS_READY) {
      return (
        <button
          className="button is-success"
          style={{width: '100%', height: '100%'}}
          onClick={this.onClickRun}
        >
          Run
        </button>
      )
    } else if (status == STATUS_IN_PROGRESS) {
      return (
        <button className="button" disabled style={{width: '100%', height: '100%'}}>In Progress</button>
      )
    } else if (status == STATUS_FAIL) {
      return (
        <button className="button is-danger" disabled style={{width: '100%', height: '100%'}}>Failed</button>
      )
    } else if (status == null) {
      return (
        <button className="button is-static is-loading" style={{width: '100%', height: '100%'}}>Run</button>
      )
    }
  }

  renderPipeline() {
    let { name } = this.state;

    return (
      <div className="column is-6">
        <div className="card">
          <div className="card-content">
            <div className="columns is-vcentered">
              <div className="column is-9">
                <p className="is-size-4 is-bold">{name}</p>
              </div>
              <div className="column is-3">
                {this.renderPipelineRight()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.renderPipeline();
  }


}

class PipelineBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pipelines: [],
      isLoading: true,
      errors: null,
    }

    this.renderPipeline = this.renderPipeline.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const callApiUrl = `pipeline/`;
    this.setState({ isLoading: true });
    callApi(
      callApiUrl,
      (result) => {
        const loadedResult = result.data || [];
        this.setState({
          error: null,
          isLoading: false,
          pipelines: loadedResult,
        })
      },
      (errors) => {
        this.setState({ errors, isLoading: false })
      },
    );
  }

  renderPipeline(pipeline) {
    return (<PipelineCard pipeline_name={pipeline.name}/>)
  }

  renderNoPipeline() {
    return (
      <div className="column is-full">
        <p>No pipeline found.</p>
      </div>
    )

  }

  renderError(errors) {
    if (errors == null) return;

    return (
      <div className="column is-full">
        <p>error: {JSON.stringify(errors)}</p>
      </div>
    )

  }

  renderAvailablePipelines() {
    let { pipelines, errors } = this.state;
    return (
      <div>
        <h1 className="title">Pipelines</h1>
        <div style={{
          borderTop: ".1rem solid #999999",
          marginBottom: "1em",
        }}/>
        <div className="columns is-multiline">
          { this.renderError(errors) }
          { pipelines.map(this.renderPipeline) }
          { pipelines.length > 0 ? null : this.renderNoPipeline()}
        </div>
      </div>
    )
  }

  render() {

    let { isLoading } = this.state;


    if (isLoading) {
      return (
        <div className="columns half-vh">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      )
    }

    return (
      <div className="container">
        {this.renderAvailablePipelines()}
      </div>
    )
  }
}


export default PipelineBrowser;
